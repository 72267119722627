<template>
  <div class="d-flex flex-column p-2 m-2">
    <label for="nameAds" class="label mt-3">
      広告名 <span class="text-danger">*</span>
      <input v-model="state.form.name" type="text" maxlength="255" id="nameAds" placeholder="広告名を入力してください"
        class="form-control" />
      <div v-if="v$.name.required.$invalid && invalid" class="error-text-show">
        {{ $t('field_required', { field: '広告名' }) }}
      </div>
    </label>

    <label for="timeAds" class="label mt-3 mb-0">
      再生時間 <span class="text-danger">*</span>
      <Multiselect v-model="state.form.appearanceTime" :options="state.optionTimes" class="multiselect-input"
        :track-by="'name'" :label="'name'" :multiple="false" :searchable="true" style="padding: 0;" :can-clear="false"
        :can-deselect="false" placeholder="再生時間" :noResultsText="$t('t-no-data')"
      ></Multiselect>
      <div v-if="v$.appearanceTime.required.$invalid && invalid" class="error-text-show">
        {{ $t('field_select_required', { field: '再生時間' }) }}
      </div>
    </label>

    <label for="statusAds" class="label mt-3 mb-0">
      有効/無効 <span class="text-danger">*</span>
      <div class="form-check form-switch mb-0">
        <input v-model="state.form.active" class="form-check-input checkbox" type="checkbox" role="switch" ref="statusAds" id="statusAds"
          checked>
      </div>
    </label>

    <div class="mt-3">
      <label class="form-label label mb-0">コンテンツ <span class="text-danger">*</span></label>
      <DropZone :prop-name-file="state.fileNameShow" :max-file-size="state.maxFileSize"
        :allowedExtensions="['image/jpg', 'image/jpeg', 'image/png', 'video/mp4', 'video/avi', 'video/mov', 'video/quicktime']"
        :accept="'image/*,video/*'" type="file-input" @selectedFile="selectedFile" :show-error-inside="false"
        @errorUpload="handleErrorUpload" />
      <DropZone :max-file-size="state.maxFileSize"
        :allowedExtensions="['image/jpg', 'image/jpeg', 'image/png', 'video/mp4', 'video/avi', 'video/mov', 'video/quicktime']"
        :accept="'image/*,video/*'" v-if="!fileAds" :type="'drop-file'" @selectedFile="selectedFile"
        :show-error-inside="false" @errorUpload="handleErrorUpload" />

        <b-progress class="mt-2" v-if="state.showProgressBar" animated :value="state.uploadProgress" variant="primary"></b-progress>

      <div v-if="fileAds && state.form.type === 'IMAGE'" class="w-100 d-flex justify-content-center container-image" style="">
        <img :src="fileAds" alt="facilityForm-image" style="object-fit: cover; max-width: 100%; max-height: 230px;">
      </div>

      <video v-else-if="fileAds && state.form.type === 'VIDEO'" class="w-100" style="max-height: 200px" controls>
        <source :src="fileAds" :type="state.type" ref="sourceVideo">
      </video>
      <div v-if="messageFileUpload !== ''" class="error-text-show">
        {{ messageFileUpload }}
      </div>
      <div v-else-if="v$.url.required.$invalid && invalid" class="error-text-show">
        {{ $t('field_select_required', { field: 'コンテンツ' }) }}
      </div>
    </div>

    <div class="mt-3">
      <b-button variant="primary" class="waves-effect waves-light w-100 d-flex justify-content-center" :disabled="state.isLoading"
        style="column-gap: 10px" @click="handleSubmit">
        <Loading v-if="state.isLoading" />
        {{ isCreate ? `+ ${$t("t-register-btn")}` : $t("t-update") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import DropZone from "@/components/widgets/dropZone";
import {computed, onMounted, reactive, ref, watch} from "vue";
import { detectExtensionFile } from "@/state/helpers";
import useVuelidate from "@vuelidate/core";
import { RULE_ADS, ROLES } from "@/constants";
import { clientOSS, generateImageOss } from "@/helpers/axios/formDataRequest";
import { adsService } from "@/services";
import Swal from "sweetalert2";
import i18n from "@/i18n";
import Loading from "@/components/Loading.vue";
import {cloneDeep, delay, get} from "lodash";
import Compressor from 'compressorjs';
import { mapGetters, useStore } from "vuex";

export default {
  name: "create",
  components: {
    Multiselect,
    DropZone,
    Loading
  },
  props: {
    isCreate: {
      type: Boolean,
      default: true
    },
    adsInfo: {
      type: Object,
      default: () => {
      }
    }
  },
  setup(props, { emit }) {
    const sourceVideo = ref(null);
    const store = useStore()
    const fileUpload = ref(null);
    const facilityId = computed(() => store.getters['settings/getFacilityId']);
    const currentUser = computed(() => store.getters['auth/currentUser']);
    const fileAds = ref('');
    const state = reactive({
      optionTimes: [],
      form: {
        url: "",
        appearanceTime: '',
        name: '',
        active: true,
        type: '',
      },
      type: '',
      isLoading: false,
      fileNameShow: '',
      maxFileSize: 0,
      uploadProgress: 0,
      showProgressBar: false
    });
    const messageFileUpload = ref('')
    const invalid = ref(false);
    const form = computed(() => state.form)
    const v$ = useVuelidate(RULE_ADS, form);

    watch(() => props.adsInfo, (value) => {
      fileAds.value = '';
      const temp = cloneDeep(value);
      delete temp.active;
      if (!Object.values(temp).every(v => v === '')) {
        const { appearanceTime, active, name, type, url } = value
        state.form.url = url;
        state.form.appearanceTime = appearanceTime;
        state.form.name = name;
        state.form.active = active;
        state.form.type = type;

        const temp = value.url.split('/');
        if (temp.length) {
          state.fileNameShow = temp[temp.length - 1];
        }

        delay(() => {
          fileAds.value = generateImageOss(url);
        }, 0)
      }
    })

    onMounted(() => {
      setOptionTime();
    });

    const setOptionTime = () => {
      for (let i = 1; i <= 40; i++) {
        state.optionTimes.push(
          { value: i * 15, name: `${i * 15} 秒` }
        )
      }
    }

    const uploadToOss = async (file, type) => {
      if(type === 'video') {
        state.showProgressBar = true
        return await uploadVideo(file)
      }

      let result;

      result = new Compressor(file, {
        maxWidth: 720,
        maxHeight: 1280,
        success: async (fileResult) => {
          return await clientOSS.put(`ads/${fileResult.name}`, fileResult);
        },
        error: (err) => {
          console.error('Image compression error:', err.message);
        },
      });

      return {name: `ads/${result.file.name}`}
    }

    const uploadVideo = async (file) => {
      return await clientOSS.multipartUpload(`ads/${file.name}`, file, {
        headers: {'x-oss-tagging': 'Tag1=1&Tag2=2', },
        partSize: 10 * 1024 * 1024,
        progress: (percentage) => {
          state.uploadProgress = Math.round(percentage * 100);
        },
      })
    }

    const selectedFile = async (file) => {
      const type = await detectExtensionFile(file);
      messageFileUpload.value = '';
      if (!type) {
        state.form.url = '';
        fileUpload.value = '';
        fileAds.value = '';
        messageFileUpload.value = 'ファイル拡張子が正しくありません。';
        state.fileNameShow = '';

        return;
      }

      if (type.type === 'video') {
        state.type = `video/${type.extension}`
        state.maxFileSize = 500
      } else {
        state.maxFileSize = 10
      }

      let uploadOss = {}
      try {
        uploadOss = await uploadToOss(file, type.type);
        state.fileNameShow = file.name
        state.showProgressBar = false
        state.uploadProgress = 0
      } catch (e) {
        console.log(e)
        return;
      }

      state.form.type = type.type.toUpperCase();
      state.form.url = uploadOss.name;
      fileUpload.value = file;
      fileAds.value = URL.createObjectURL(file);
    }

    const showPopup = async (message, type, timer = 1500) => {
      await Swal.fire({
        title: "",
        html: message,
        icon: type,
        showConfirmButton: false,
        timer: timer,
      });
    }

    const initForm = () => {
      fileUpload.value = null;
      fileAds.value = '';
      state.form = {
        url: "",
        appearanceTime: '',
        name: '',
        active: true,
        type: ''
      };
      state.fileNameShow = ''
    }

    const handleSubmit = async () => {
      v$.value.$touch();
      invalid.value = v$.value.$invalid;

      if (!invalid.value && messageFileUpload.value === '') {
        state.isLoading = true;
        if (props.isCreate) {
          try {
            state.form = {...state.form, name: state.form.name.trim(), facilityId: facilityId.value};
            if (currentUser.value.roles == ROLES.ADMIN) {
              delete state.form.facilityId;
            }

            await adsService.createAds(state.form);
            emit('onCreateSuccess');
            initForm()
            await showPopup(i18n.global.t('msg.saved'), 'success')

          } catch (err) {
            let message = err?.response?.data?.message || err?.message || '';

            await showPopup(message, 'error', 3000)
          } finally {
            state.isLoading = false;
          }
        } else {
          try {
            state.form = {...state.form, name: state.form.name.trim()};
            await adsService.updateAds(props.adsInfo.id, state.form);
            await showPopup(i18n.global.t('msg.saved'), 'success');
            emit('onCreateSuccess');
            initForm()
          } catch (err) {
            let message = err?.response?.data?.message || err?.data?.message || err?.message || ''
            await showPopup(message, 'error', 3000)
          } finally {
            state.isLoading = false;
          }
        }
      }
    }

    const handleErrorUpload = (value) => {
      messageFileUpload.value = value
    }

    const statusAds = ref(null);

    return {
      messageFileUpload,
      v$,
      invalid,
      state,
      setOptionTime,
      selectedFile,
      fileAds,
      statusAds,
      handleSubmit,
      handleErrorUpload,
      sourceVideo,
      facilityId,
      ROLES,
      currentUser,
    }
  },
}
</script>

<style scoped lang="scss">
.label {
  font-size: var(--vz-body-font-size);
}

.multiselect-input ::v-deep {
  .multiselect-wrapper {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    min-height: calc(var(--ms-border-width, 1px) * 2 + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375) + var(--ms-py, .5rem) * 2 - 2px);
    outline: none;
    position: relative;
    width: 100%;
  }
}

.checkbox {
  font-size: 20px;
}

.form-switch {
  padding-left: 4.5em;
}

.error-text-show {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ed5e5e;
}
.container-image {
  max-height: 230px;
  border-radius: 2px;
  border: 1px dashed #CBD2D8;
  background: #202224;
}
</style>
