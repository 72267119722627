<template>
  <div class="card-body">
    <div class="card-header border-0" v-if="isShowSearch || isShowPerPage">
      <div class="row g-4">
        <div v-if="isShowSearch" class="col-sm">
          <div class="d-flex justify-content-sm-start" style="column-gap: 10px">
            <div class="search-box">
              <input
                type="text"
                class="form-control"
                @keyup.enter="handleSearch"
                v-model="querySearch.search"
                :placeholder="`${$t('t-search')}`"
              />
              <i class="ri-search-line search-icon"></i>
            </div>
            <div>
              <b-button
                variant="primary"
                class="waves-effect waves-light"
                @click="handleSearch"
              >
                <div class="flex">
                  {{ $t("t-search") }}
                </div>
              </b-button>
            </div>
          </div>
        </div>
        <div v-if="isShowPerPage" class="col-sm-3">
          <div class="dataTables_length">
            <label
              class="d-flex justify-content-end align-items-center"
              style="column-gap: 5px; font-size: 12px"
            >
              <select
                name="example_length"
                aria-controls="example"
                @change="handleChangePerPage"
                class="form-select form-control"
                style="width: 85px"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span style="min-width: 65px">{{ $t("entries_page") }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive p-3">
      <table
        class="table align-middle table-nowrap mb-0 table-hover table-bordered"
      >
        <thead class="table-light">
          <tr>
            <th
              :style="`
         ${head.widthCustom ? `width: ${head.widthCustom}px` : ``}
          `"
              class="sort"
              data-sort="currency_name"
              scope="col"
              v-for="(head, index) in state.fields"
              :key="`head_${index}`"
            >
              <div
                class="d-flex w-100 justify-content-between align-items-center"
              >
                <div style="line-height: 24px">{{ head.label }}</div>
                <div
                  v-if="head.sortable"
                  @click="sort(head.key)"
                  style="width: 24px"
                >
                  <div
                    v-if="
                      head.key !== querySearch.sortBy || state.countSort === 0
                    "
                    class="cursor-pointer"
                  >
                    <i
                      class="ri-expand-up-down-fill font-16"
                      style="color: #acadaf"
                    ></i>
                    <!--                  <i class="ri-arrow-up-down-line "></i>-->
                  </div>

                  <div class="d-flex flex-row" v-else>
                    <i
                      class="ri-arrow-up-s-fill cursor-pointer d-flex align-items-center justify-content-center font-16"
                      style="padding: 0 4px"
                      :class="getClassActive(head.key, 'ASC')"
                    />
                    <i
                      style="padding: 0 4px"
                      class="ri-arrow-down-s-fill cursor-pointer d-flex align-items-center justify-content-center font-16"
                      :class="getClassActive(head.key, 'DESC')"
                    />
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="!state.loading">
            <tr v-for="(row, index) in state.items" :key="`row_${index}`">
              <td
                :style="`max-width: ${cel.maxWidth}px`"
                v-for="(cel, indexCell) in state.fields"
                :key="`cel_${indexCell}`"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                class="text-truncate fs-12 fw-medium text-normal"
                :title="
                  !cel.hasOwnProperty('options') && cel['type'] !== 'status'
                    ? cel.hasOwnProperty('specialTooltip') &&
                      cel['specialTooltip']
                      ? formatCell(row, cel, index)
                          .toString()
                          .replaceAll('<br/>', '\n')
                      : formatCell(row, cel, index)
                    : ''
                "
              >
                <div
                  class="text-truncate"
                  v-if="!cel.hasOwnProperty('options')"
                  v-html="formatCell(row, cel, index)"
                ></div>
                <div v-else>
                  <!-- Edit Button -->
                  <button
                    :disabled="
                      cel['options'].findIndex((item) => item === 'edit') ===
                        -1 ||
                      cel?.isDisabled?.(row) ||
                      cel?.isDisabledEdit?.(row) ||
                      false
                    "
                    class="btn btn-primary w-sm"
                    size="sm"
                    @click="
                      () =>
                        handleEdit(
                          row.id,
                          cel?.isDisabled?.(row) ||
                            cel?.isDisabledEdit?.(row) ||
                            false
                        )
                    "
                  >
                    {{ $t("t-edit") }}
                  </button>

                  <!-- Delete Button -->
                  <button
                    :disabled="
                      !(
                        checkShowChangeRow(row) &&
                        cel['options'].findIndex((item) => item === 'delete') >
                          -1 &&
                        setStatusColumn(row, cel) === 'true'
                      ) ||
                      cel?.isDisabled?.(row) ||
                      cel?.isDisabledDelete?.(row) ||
                      false
                    "
                    size="sm"
                    class="btn btn-danger ms-2 w-sm"
                    @click="
                      () =>
                        handleDelete(
                          row.id,
                          cel.hasOwnProperty('dynamicGetName')
                            ? get(row, cel.dynamicGetName, '')
                            : row.name,
                          get(row, 'title', ''),
                          cel?.isDisabled?.(row) || false
                        )
                    "
                  >
                    {{ $t("t-delete") }}
                  </button>

                  <!-- Reboot Button -->

                  <!--
                  <button
                    :disabled="
                      cel['options'].findIndex((item) => item === 'reboot') ===
                        -1 || !rebootCheck(row.room?.statusSensor, row.isOnline)
                    "
                    class="btn btn-dark ms-2 w-sm"
                    size="sm"
                    @click="() => handleReboot(row.id)"
                  >
                    {{ $t("t-reboot-sensor") }}
                  </button>
                  -->
                </div>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td :colspan="columns.length" class="text-center p-5">
              <Loading />
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="state.items.length === 0 && !state.loading"
        class="d-flex justify-content-center align-items-center no-results mb-4"
      >
        データがありません。
      </div>

      <div
        class="w-100"
        :class="state.items.length ? 'mt-3' : ''"
        v-if="state.pagination"
        style="border-bottom: 0; border-bottom-color: transparent"
      >
        <div class="d-flex justify-content-end">
          <Pagination :pagination="state.pagination" @onChangePage="paginate" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, watch } from "vue";
import Loading from "@/components/Loading.vue";
import { DATE_FORMAT, SENSOR_STATUS } from "@/constants";
import axios from "@/helpers/axios";
import { cloneDeep, get, isEqual } from "lodash";
import * as moment from "moment";
import Pagination from "@/components/pagination";
import i18n from "@/i18n";

export default {
  compatConfig: { MODE: 3 },
  name: "TableCommon",
  components: {
    Loading,
    Pagination,
  },
  props: {
    endpoint: {
      type: String,
      default: "",
    },
    query: {
      type: Object,
      default: () => {},
    },
    columns: {
      type: Array,
      default: () => [],
    },
    dataTable: {
      type: Array,
      default: () => [],
    },
    isShowSearch: {
      type: Boolean,
      default: true,
    },
    isShowChange: {
      type: String,
      default: "true",
    },
    isShowPerPage: {
      type: Boolean,
      default: true,
    },
    checkShowChange: {
      type: Function,
      default: (row) => {
        return true;
      },
    },
  },

  methods: {
    checkShowChangeRow(row) {
      if (this.checkShowChange) {
        return this.checkShowChange(row);
      } else {
        return true;
      }
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      countSort: 0,
      // column of the table
      fields: props.columns,
      // data of the table
      items: props.dataTable,
      // loading table
      isBusy: false,
      search: "",
      pagination: null,
      loading: false,
    });
    const querySearch = reactive({
      search: "",
      page: 1,
      pageSize: 10,
      sortBy: "createdAt",
      sortOrder: "DESC",
      startDay: moment().format("YYYY-MM-DD"),
      endDay: moment().format("YYYY-MM-DD"),
      ...props.query,
    });

    const sort = (key) => {
      state.countSort++;
      let typeDESC = true;
      if (querySearch.sortBy === key) {
        typeDESC = !(querySearch.sortOrder === "DESC");
      }
      querySearch.sortBy = key;
      querySearch.sortOrder = typeDESC ? "DESC" : "ASC";

      getDataFromEndPoint();
    };

    watch(
      () => props.endpoint,
      () => {
        getDataFromEndPoint();
      }
    );

    const getDataFromEndPoint = async () => {
      if (props.endpoint !== "") {
        state.loading = true;
        try {
          const queryTemp = new URLSearchParams(querySearch);
          const res = await axios.get(`${props.endpoint}?${queryTemp}`);
          if (isEqual(res, [])) return;
          state.items = cloneDeep(res?.data);
          state.pagination = cloneDeep(res?.pagination);
        } catch (e) {
          console.log("getDataFromEndPoint", e);
        } finally {
          state.loading = false;
        }
      }
    };

    const rebootCheck = (value, online) => {
      if (typeof value === "undefined" || !online) return false;

      return (
        value === SENSOR_STATUS.NORMAL_STARTUP ||
        value === SENSOR_STATUS.ERROR ||
        value === SENSOR_STATUS.CONNECTION_LOST
      );
    };

    const formatDate = (date) => {
      return moment(date).format(DATE_FORMAT);
    };

    const getValue = (totalData, key) => {
      const test = get(totalData, key, "");
      console.log(test, "test");
    };

    const getMultiLanguage = (text) => {
      return i18n.global.t(text);
    };

    const setStatusColumn = (totalData, value) => {
      if (totalData.publicTime && totalData.status !== "PUBLICED") {
        return "true";
      }
      if (typeof value.conditionDelete !== "undefined") {
        return value.conditionDelete;
      }

      return "true";
    };

    const formatCell = (totalData, value, index) => {
      let result = "";
      if (typeof value.dynamicText !== "undefined") {
        return value.dynamicText(totalData);
      }
      if (typeof value.type === "undefined")
        return get(totalData, value.key, "");

      switch (value.type) {
        case "stt": {
          result = ++index;
          break;
        }
        case "datetime": {
          result = formatDate(get(totalData, value.key, ""));
          break;
        }
        case "status": {
          const valueTemp = get(totalData, value.key, "");

          if (valueTemp === "ACTIVE") {
            result = `
            <div class="d-flex flex-row align-items-center">
                <i class="bx bx-check-circle fs-18 text-success"></i>
                <p class="mb-0 ps-1 fw-semibold text-normal">

            `;
            result += i18n.global.t("t-enable");
            result += `</p>
            </div>`;
          } else {
            result = `<div class="d-flex flex-row align-items-center">
              <i class="bx bx-x-circle fs-18 text-danger"></i>
              <p class="mb-0 ps-1 fw-semibold text-normal">`;

            result += i18n.global.t("t-disable");

            result += `</p>
            </div>`;
          }

          break;
        }
        default: {
          result = get(totalData, value.key, "");
          break;
        }
      }

      return result;
    };

    const handleEdit = (id, status) => {
      if (status) return;
      emit("onEdit", id);
    };

    const handleDelete = (id, name, title, status) => {
      if (status) return;
      emit("onDelete", { id, name, title });
    };

    const handleReboot = (id) => {
      emit("onReboot", id);
    };

    const refreshTable = () => {
      getDataFromEndPoint();
    };

    const paginate = (pageNumber) => {
      querySearch.page = pageNumber;
      getDataFromEndPoint();
    };

    onMounted(() => {
      getDataFromEndPoint();
    });

    /* watch(() => [querySearch.page, querySearch.pageSize, querySearch.sortOrder, querySearch.sortBy], () => {
      getDataFromEndPoint()
    })*/

    /*watch(querySearch, () => {
      getDataFromEndPoint()
    })*/

    watch(
      () => [props.query?.facilityId],
      (value) => {
        querySearch["facilityId"] = value;
        getDataFromEndPoint();
      }
    );

    watch(
      () => [props.query.startDay, props.query.endDay, props.query.pageSize],
      (value) => {
        const [startDay, endDay, pageSize] = value;
        if (typeof startDay !== "undefined") {
          querySearch["startDay"] = startDay;
        }
        if (typeof endDay !== "undefined") {
          querySearch["endDay"] = endDay;
        }
        if (typeof pageSize !== "undefined") {
          querySearch["pageSize"] = parseInt(pageSize);
        }
        getDataFromEndPoint();
      }
    );

    const handleChangePerPage = (e) => {
      querySearch.pageSize = e.target.value;
      querySearch.page = 1;
      getDataFromEndPoint();
    };

    const getClassActive = (key, type) => {
      let display = "d-block";
      if (querySearch.sortBy === key && querySearch.sortOrder !== type) {
        display = "d-none";
      }

      return display;
    };

    const handleSearch = () => {
      querySearch.page = 1;
      querySearch.search = querySearch.search.trim();

      getDataFromEndPoint();
    };

    return {
      querySearch,
      state,
      sort,
      get,
      formatCell,
      setStatusColumn,
      handleEdit,
      handleDelete,
      refreshTable,
      paginate,
      handleChangePerPage,
      getClassActive,
      getValue,
      getMultiLanguage,
      handleReboot,
      rebootCheck,
      handleSearch,
      getDataFromEndPoint,
    };
  },
};
</script>

<style scoped lang="scss">
.sort-active {
  background-color: #cccc;
  border-radius: 54%;
}

.font-16 {
  font-size: 16px;
}
.no-results {
  height: 100px;
  border: 1px solid var(--vz-border-color);
}
.text-truncate-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.table ::v-deep {
  .badge {
    width: 109px;
    padding: 4.5px 0;
    font-size: 11px !important;
  }
  .bg-success-subtle {
    background-color: #d9f5f0 !important;
  }
  .bg-warning-subtle {
    background-color: #fff5da !important;
  }
  .bg-danger-subtle {
    background-color: #fde8e4 !important;
  }
  .bg-secondary-subtle {
    background-color: #ededf0 !important;
  }
}
</style>
