<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import TableCommon from "@/components/common/TableCommonVue3.vue";
import i18n from "@/i18n";
import { ADMIN_API_PATH } from "@/constants";
import { computed, h, onMounted, reactive, ref, watch } from "vue";
import moment from "moment";
import vSelect from "vue-select";
import { facilityService, roomService } from "@/services";
import { cloneDeep, debounce, isEqual } from "lodash";
import {
  helpers,
  maxLength,
  minLength,
  required,
  sameAs,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Swal from "sweetalert2";
import { SENSOR_STATUS, ROLES } from "@/constants";
import { userService } from "@/services";
import Loading from "@/components/Loading";

export default {
  setup() {
    const title = i18n.global.t("sensor.management");
    const items = [
      {
        text: i18n.global.t("t-home"),
        href: "/",
      },
      {
        text: i18n.global.t("sensor.management"),
        active: true,
      },
    ];
    const tableTablet = ref(null);
    const userInfo = ref(null);
    const passwordInfo = ref(null);

    const initDefault = {
      facilityId: "",
      status: true,
      role: ROLES.TABLET,
      pathAvatar: "",
      pathCover: "",
    };

    const initDefaultRecord = {
      ...initDefault,
      tabletId: "",
      firstName: "",
      lastName: "",
      facilityName: "",
    };

    const initDefaultPassword = {
      newPassword: "",
      confirmPassword: "",
      tabletId: "",
    };

    const state = reactive({
      search: "",
      facilities: [],
      user: { ...initDefault },
      id: "",
      userEdit: {
        ...initDefaultRecord,
      },
      userPassword: { ...initDefaultPassword },
      isHideNewPassword: true,
      isHideConfirmPassword: true,
      isLoading: false,
      prevFacilities: [],
    });

    const rules = {
      user: {
        facilityId: {
          required: helpers.withMessage(
            i18n.global.t("field_select_required", {
              field: i18n.global.t("sensor.facilityName"),
            }),
            required
          ),
        },
      },
      userEdit: {
        tabletId: {
          required: helpers.withMessage(
            i18n.global.t("field_required", {
              field: i18n.global.t("t-tabletId"),
            }),
            required
          ),
          maxLength: helpers.withMessage(
            i18n.global.t("field_maxlength", {
              maxlength: 255,
              field: i18n.global.t("t-tabletId"),
            }),
            maxLength(255)
          ),
        },
      },
      userPassword: {
        newPassword: {
          required: helpers.withMessage(
            i18n.global.t("field_required", {
              field: i18n.global.t("t-new-password"),
            }),
            required
          ),
          maxLength: helpers.withMessage(
            i18n.global.t("field_maxlength", {
              maxlength: 255,
              field: i18n.global.t("t-new-password"),
            }),
            maxLength(255)
          ),
          minLength: helpers.withMessage(
            i18n.global.t("field_minlength", {
              minlength: 6,
              field: i18n.global.t("t-new-password"),
            }),
            minLength(6)
          ),
        },
        confirmPassword: {
          required: helpers.withMessage(
            i18n.global.t("field_required", {
              field: i18n.global.t("t-new-password-confirm"),
            }),
            required
          ),
          sameAs: helpers.withMessage(
            i18n.global.t("t-confirm-password-not-match"),
            sameAs(computed(() => state.userPassword.newPassword))
          ),
        },
      },
    };

    const v$ = useVuelidate(rules, state);

    const columns = [
      {
        sortable: false,
        label: "",
        getShowColumn: (hasItem) => hasItem,
        type: "stt",
        maxWidth: 60,
        renderCell: (sensor, rowIndex) => {
          return h("span", rowIndex + 1);
        },
      },
      {
        key: "facility.name",
        sortKey: "facility.name",
        headerClass: "text-center",
        cellClass: "text-left",
        sortable: true,
        label: i18n.global.t("t-name-of-facility"),
        maxWidth: 280,
      },
      {
        key: "tabletId",
        sortable: true,
        label: i18n.global.t("t-tabletId"),
        headerClass: "text-center",
        cellClass: "text-center",
        maxWidth: 120,
        renderCell: (sensor) => {
          return h("span", sensor.tabletId);
        },
      },
      {
        key: "status",
        headerClass: "text-center margin-header",
        sortable: false,
        label: i18n.global.t("t-enable-disable"),
        renderCell: (sensor) => {
          if (sensor.status === "ACTIVE") {
            return h(
              "div",
              {
                class:
                  "d-flex flex-row align-items-center justify-content-center",
              },
              [
                h("i", { class: "bx bx-check-circle fs-18 text-success" }),
                h(
                  "p",
                  { class: "mb-0 ps-1 fw-semibold text-normal" },
                  i18n.global.t("t-enable")
                ),
              ]
            );
          } else {
            return h(
              "div",
              {
                class:
                  "d-flex flex-row align-items-center justify-content-center",
              },
              [
                h("i", { class: "bx bx-x-circle fs-18 text-danger" }),
                h(
                  "p",
                  { class: "mb-0 ps-1 fw-semibold text-normal" },
                  i18n.global.t("t-disable")
                ),
              ]
            );
          }
        },
      },
      {
        key: "online",
        sortable: false,
        label: i18n.global.t("sensor.onlineState"),
        headerClass: "text-center margin-header",
        renderCell: (sensor) => {
          const className =
            sensor.isOnline && sensor.roomId
              ? "status status-valid fs-11"
              : "status status-expired fs-11";
          return h(
            "label",
            { class: className },
            i18n.global.t(
              sensor.isOnline && sensor.roomId ? "t-online" : "t-offline"
            )
          );
        },
      },
      {
        key: "state",
        sortable: false,
        cellClass: "text-center",
        label: i18n.global.t("sensor.state"),
        renderCell: (sensor) => {
          const className =
            sensor.isOnline && sensor.roomId
              ? sensorStatusClassName[sensor.room?.statusSensor]
              : "errorClass";
          return h(
            "div",
            { class: className },
            sensor.isOnline && sensor.roomId
              ? i18n.global.t(sensorStatus[sensor.room?.statusSensor])
              : "-"
          );
        },
      },
      {
        key: "createdAt",
        headerClass: "text-center",
        sortable: true,
        label: i18n.global.t("t-registration-date"),
        renderCell: (sensor) => {
          return h(
            "div",
            { class: "text-center" },
            moment(sensor.createdAt).format("YYYY-MM-DD HH:mm:ss")
          );
        },
      },
      {
        key: "updatedAt",
        headerClass: "text-center",
        sortable: true,
        label: i18n.global.t("t-last-updated"),
        renderCell: (sensor) => {
          return h(
            "div",
            { class: "text-center" },
            moment(sensor.updatedAt).format("YYYY-MM-DD HH:mm:ss")
          );
        },
      },
      {
        key: "action",
        type: "action",
        getShowColumn: (hasItem) => hasItem,
        sortable: false,
        label: "",
        renderCell: (tablet) => {
          return (
            <div class="d-flex gap-2">
              <b-button
                style="width: 58px"
                disabled={tablet.isOnline && tablet.roomId}
                onClick={() => editTablet(tablet)}
                variant="primary"
                class="variant-primary"
              >
                {i18n.global.t("t-edit")}
              </b-button>
              <b-button
                style="width: 58px"
                disabled={tablet.isOnline && tablet.roomId}
                onClick={() => deleteTablet(tablet)}
                variant="danger"
                class="variant-danger"
              >
                {i18n.global.t("t-delete")}
              </b-button>
              <b-button
                style="width: 58px; padding: 0px"
                disabled={
                  !isRebootShow(tablet.room?.statusSensor, tablet.isOnline)
                }
                onClick={() => rebootSensor(tablet.id)}
                variant="dark"
                class="variant-dark"
              >
                {i18n.global.t("t-reboot-sensor")}
              </b-button>
            </div>
          );
        },
      },
    ];

    const sensorStatus = {
      pending: "sensorStatus.pending",
      connecting: "sensorStatus.connecting",
      rebooting: "sensorStatus.reboot",
      error: "sensorStatus.error",
      normal_startup: "sensorStatus.normal_startup",
      connection_lost: "sensorStatus.connection_lost",
    };

    const sensorStatusClassName = {
      pending: "errorClass",
      connecting: "connectingClass",
      rebooting: "rebootingClass",
      error: "errorClass",
      normal_startup: "normal_startupClass",
      connection_lost: "connection_lostClass",
    };

    const isRebootShow = (status, online) => {
      if (!status || !online) return false;

      return (
        status === SENSOR_STATUS.NORMAL_STARTUP ||
        status === SENSOR_STATUS.ERROR ||
        status === SENSOR_STATUS.CONNECTION_LOST
      );
    };

    const createOrUpdate = async () => {
      try {
        v$.value.user.$touch();
        if (v$.value.user.$invalid) return;
        state.isLoading = true;
        const response = await userService.createUser({
          ...state.user,
          status: state.user.status ? "ACTIVE" : "INACTIVE",
        });
        if (response && !response.error) {
          await Swal.fire({
            title: "",
            text: i18n.global.t("msg.saved"),
            icon: "success",
            showConfirmButton: true,
            html: `<div class="d-flex flex-column flex-start">
                <h6 class="fs-16 text-center">${i18n.global.t(
                  "t-tablet-create"
                )}</h6>
                <div class="mt-4 d-flex flex-column flex-start">
                  <p class="fs-14 p-0 text-start ">
                    <span class="box-120">
                      ${i18n.global.t("t-create-success-tabletId")}
                      </span>
                    ${response.tabletId}</p>
                  <p class="fs-14 p-0 text-start ">
                    <span class="box-120">
                      ${i18n.global.t("t-password")}:
                    </span>
                    ${response.password}
                  </p>
                </div>
              </div>`,
          });
          state.user = { ...initDefault };
          v$.value.user.$reset();
          tableTablet.value.refreshTable();
        } else {
          if (response.statusCode === 409) {
            let message = "error";
            if (response.message.toLowerCase().includes("facility")) {
              message = i18n.global.t("t-error-exists", {
                field: i18n.global.t("t-facility-name"),
              });
            }
            await Swal.fire({
              title: "",
              text: message,
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      } catch (e) {
        console.log("createOrUpdate", e);
      } finally {
        state.isLoading = false;
      }
    };

    const resetEditing = () => {
      state.userEdit = { ...initDefaultRecord };
      state.userPassword = { ...initDefaultPassword };
      state.id = "";
      v$.value.userEdit.$reset();
      v$.value.userPassword.$reset();
    };

    const editTablet = (tablet) => {
      const {
        password,
        firstName,
        lastName,
        id,
        facilityId,
        role,
        status,
        pathAvatar,
        pathCover,
        tabletId,
        facility: { name = "" },
      } = tablet;
      state.id = id;
      state.userEdit = {
        firstName,
        lastName,
        facilityId,
        status: status === "ACTIVE" ? true : false,
        role,
        pathAvatar,
        pathCover,
        facilityName: name,
        tabletId,
      };

      userInfo.value = cloneDeep(state.userEdit);

      state.userPassword = {
        newPassword: "",
        confirmPassword: "",
        tabletId,
      };

      passwordInfo.value = cloneDeep(state.userPassword);
    };

    const updateInfo = async () => {
      v$.value.userEdit.$touch();
      if (v$.value.userEdit.$invalid) return;
      state.isLoading = true;
      const response = await userService.updateUser(state.id, {
        ...state.userEdit,
        status: state.userEdit.status ? "ACTIVE" : "INACTIVE",
      });

      if (response && !response.error) {
        await Swal.fire({
          title: "",
          text: i18n.global.t("msg.saved"),
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        resetEditing();
        tableTablet.value.refreshTable();
      } else {
        if (response.statusCode === 409) {
          let message = "error";
          if (response.message.includes("tabletId")) {
            message = i18n.global.t("t-error-exists", {
              field: i18n.global.t("t-tabletId"),
            });
          }

          await Swal.fire({
            title: "",
            text: message,
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      state.isLoading = false;
    };

    const deleteTablet = async (tablet) => {
      const { id, tabletId } = tablet;
      const accept = await Swal.fire({
        title: `<h2 class='swal2-title' id='swal2-title' style='display: block; font-size: 20px !important;'>${i18n.global.t(
          "deleted_message",
          { message: tabletId }
        )}</h2>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: i18n.global.t("yes"),
        cancelButtonText: i18n.global.t("cancel"),
      });

      if (accept.value) {
        try {
          const response = await userService.deleteUser(id);
          if (response && response.deleted) {
            if (isEqual(state.id, id)) {
              resetEditing();
            }
            tableTablet.value.refreshTable();
          }
        } catch (e) {
          await Swal.fire({
            title: "",
            text: e.message,
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    };

    const rebootSensor = async (sensorId) => {
      try {
        const accept = await Swal.fire({
          title: `<h2 class='swal2-title' id='swal2-title' style='display: block; font-size: 20px !important;'>${i18n.global.t(
            "t-reboot-msg"
          )}</h2>`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: i18n.global.t("yes"),
          cancelButtonText: i18n.global.t("cancel"),
        });
        if (accept.value) {
          await roomService.rebootSensor(sensorId);
          tableTablet.value.refreshTable();
        }
      } catch (e) {
        await Swal.fire({
          title: "",
          html: `<h5 style="white-space: pre-line;font-weight: bold;">${i18n.global.t(
            "msg.sensor-not-online"
          )}</h5>`,
          icon: "error",
          showConfirmButton: true,
        });
      }
    };

    const getFacilities = async () => {
      try {
        const params = {
          search: state.search,
          page: 1,
          pageSize: 9999,
          sortBy: "createdAt",
          sortOrder: "DESC",
        };

        const response = await facilityService.getFacilities(params);
        const data = response.data || [];
        state.facilities = data.map((d) => ({
          label: `${d.name} (${d.facilityIdStr})`,
          value: d.id,
        }));
      } catch (e) {
        console.log(e, "getFacilities");
      }
    };

    const selectedFacilityChange = (selectedFacility) => {
      state.prevFacilities = [selectedFacility];
    };

    const selectFacilityClose = () => {
      if (
        !state.facilities.map((f) => f.value).includes(state.user.facilityId) &&
        state.prevFacilities.length > 0
      ) {
        state.facilities = state.prevFacilities;
      }
    };

    const searchFacility = async (searchValue) => {
      state.search = searchValue;
      if (searchValue || (!searchValue && !state.user.facilityId)) {
        getFacilities();
      }
    };

    const updatePassword = async () => {
      try {
        v$.value.userPassword.$touch();
        if (v$.value.userPassword.$invalid) return;
        await userService.changeUserPasswdByAdmin(state.id, {
          new: state.userPassword.newPassword,
        });
        await Swal.fire({
          title: "",
          text: i18n.global.t("msg.saved"),
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        resetEditing();
        tableTablet.value.refreshTable();
      } catch (e) {
        console.log("updatePassword", e);
      }
    };

    function toggleNewPassword() {
      state.isHideNewPassword = !state.isHideNewPassword;
    }

    function toggleConfirmPassword() {
      state.isHideConfirmPassword = !state.isHideConfirmPassword;
    }

    function onResetPasswordValidationField(field) {
      v$.value.userPassword[field].$reset();
    }

    function onResetInfoValidationField(field) {
      v$.value.userEdit[field].$reset();
    }

    function getIconClass(hide, isError) {
      if (hide && !isError) {
        return "bx bx-show";
      }

      if (!hide && !isError) {
        return "bx bx-hide";
      }

      if (hide && isError) {
        return "bx bx-show right-3";
      }

      if (!hide && isError) {
        return "bx bx-hide right-3";
      }

      return hide ? "bx bx-show" : "bx bx-hide";
    }

    function cancelUpdateInfo() {
      state.userEdit = cloneDeep(userInfo.value);
      v$.value.userEdit.$reset();
    }

    function cancelUpdatePw() {
      state.userPassword = cloneDeep(passwordInfo.value);
      v$.value.userPassword.$reset();
    }

    const debounceSearchFacility = debounce(
      (searchValue = "") => searchFacility(searchValue),
      500
    );

    const onClear = (e) => {
      console.log("onClear", e);
    };

    onMounted(() => {
      getFacilities();
    });

    watch(
      () => state.user.facilityId,
      (newFacilityId) => {
        if (!newFacilityId) {
          state.search = "";
          getFacilities();
        }
      },
      { deep: true }
    );

    return {
      title,
      items,
      ADMIN_API_PATH,
      columns,
      tableTablet,
      sensorStatus,
      editTablet,
      deleteTablet,
      createOrUpdate,
      state,
      debounceSearchFacility,
      v$,
      updateInfo,
      toggleNewPassword,
      toggleConfirmPassword,
      onResetInfoValidationField,
      onResetPasswordValidationField,
      getIconClass,
      updatePassword,
      cancelUpdateInfo,
      cancelUpdatePw,
      onClear,
      selectedFacilityChange,
      selectFacilityClose,
    };
  },
  components: {
    Layout,
    PageHeader,
    TableCommon,
    vSelect,
    Loading,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-3" style="flex: 1">
        <div v-if="!state.id" class="card rounded-3">
          <div class="card-header">
            <h6 class="fs-16 mb-0">{{ $t("sensor.accountReg") }}</h6>
          </div>
          <div class="card-body shadow-lg">
            <form class="row gap-2" @submit.prevent="createOrUpdate">
              <div class="col-lg-12">
                <label
                  >{{ $t("sensor.facilityName") }}
                  <span class="text-danger">*</span></label
                >
                <v-select
                  class="style-chooser"
                  id="facilityId"
                  :options="state.facilities"
                  :placeholder="
                    $t('placeholder.pleaseSelect', {
                      field: $t('sensor.facilityName'),
                    })
                  "
                  :reduce="(facility) => facility.value"
                  v-model="state.user.facilityId"
                  label="label"
                  :filterable="false"
                  :searchable="true"
                  @search="(query) => debounceSearchFacility(query)"
                  @option:selected="selectedFacilityChange"
                  @close="selectFacilityClose"
                  :class="{ 'is-invalid': v$.user.facilityId.$error }"
                >
                  <template #no-options="{ search, searching, loading }">{{
                    $t("t-no-data")
                  }}</template>
                  <template #list-footer>
                    <li v-show="hasNextPage" ref="load" class="loader"></li>
                  </template>
                </v-select>
                <div
                  v-if="v$.user.facilityId.$error"
                  class="invalid-feedback d-flex flex-column"
                >
                  <span v-if="v$.user.facilityId.required.$invalid">{{
                    v$.user.facilityId.required.$message
                  }}</span>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="d-flex flex-column">
                  <label
                    >{{ $t("t-enable-disable") }}
                    <span class="text-danger">*</span></label
                  >
                  <div class="form-check form-switch mb-2">
                    <input
                      class="form-check-input checkbox"
                      type="checkbox"
                      role="switch"
                      id="status222"
                      v-model="state.user.status"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="w-100"
                  :disabled="state.isLoading"
                >
                  <div class="d-flex justify-content-center">
                    <span
                      class="d-flex align-items-center mr-2"
                      v-if="state.isLoading"
                    >
                      <Loading />
                    </span>
                    {{ $t("sensor.accountRegConfirm") }}
                  </div>
                </b-button>
              </div>
            </form>
          </div>
        </div>
        <template v-else>
          <div class="card rounded-3">
            <div class="card-header">
              <h6 class="fs-16 mb-0">{{ $t("sensor.accountEdit") }}</h6>
            </div>
            <div class="card-body shadow-lg">
              <form class="row gap-2" @submit.prevent="updateInfo">
                <div class="col-lg-12">
                  <label>{{ $t("t-facility-name") }}</label>
                  <input
                    readonly
                    disabled
                    class="form-control"
                    v-model="state.userEdit.facilityName"
                  />
                </div>
                <div class="col-lg-12">
                  <label
                    >{{ $t("t-tabletId") }}
                    <span class="text-danger">*</span></label
                  >
                  <input
                    class="form-control"
                    v-model="state.userEdit.tabletId"
                  />
                  <div
                    v-if="v$.userEdit.tabletId.$error"
                    class="invalid-feedback d-flex flex-column"
                  >
                    <span v-if="v$.userEdit.tabletId.required.$invalid">{{
                      v$.userEdit.tabletId.required.$message
                    }}</span>
                    <span
                      v-if="
                        v$.userEdit.tabletId.maxLength.$invalid &&
                        !v$.userEdit.tabletId.required.$invalid
                      "
                      >{{ v$.userEdit.tabletId.maxLength.$message }}</span
                    >
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="d-flex flex-column">
                    <label
                      >{{ $t("t-enable-disable") }}
                      <span class="text-danger">*</span></label
                    >
                    <div class="form-check form-switch mb-2">
                      <input
                        class="form-check-input checkbox"
                        type="checkbox"
                        role="switch"
                        id="status222"
                        v-model="state.userEdit.status"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="w-100 d-flex justify-content-end gap-2">
                    <b-button
                      type="submit"
                      variant="primary"
                      :disabled="state.isLoading"
                    >
                      <div class="d-flex justify-content-center">
                        <span
                          class="d-flex align-items-center mr-2"
                          v-if="state.isLoading"
                        >
                          <Loading />
                        </span>
                        {{ $t("t-update") }}
                      </div>
                    </b-button>
                    <b-button @click="cancelUpdateInfo" variant="success">{{
                      $t("t-cancel")
                    }}</b-button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="card rounded-3">
            <div class="card-header">
              <h6 class="fs-16 mb-0">{{ $t("t-change-password") }}</h6>
            </div>
            <div class="card-body shadow-lg">
              <form class="row gap-2" @submit.prevent="updatePassword">
                <div class="col-lg-12">
                  <label>{{ $t("t-tabletId") }}</label>
                  <input
                    readonly
                    disabled
                    class="form-control"
                    v-model="state.userPassword.tabletId"
                  />
                </div>
                <div class="col-lg-12">
                  <label for="newpasswordInput" class="form-label"
                    >{{ $t("t-new-password")
                    }}<span class="text-danger">*</span></label
                  >
                  <div class="form-icon right">
                    <input
                      :type="state.isHideNewPassword ? 'password' : 'text'"
                      class="form-control form-control-icon"
                      id="newpasswordInput"
                      :placeholder="$t('t-enter-new-password')"
                      v-model="state.userPassword.newPassword"
                      :class="{
                        'is-invalid':
                          submitted && v$.password.newPassword.$error,
                      }"
                      @focus="
                        () => onResetPasswordValidationField('newPassword')
                      "
                    />
                    <i
                      :class="
                        getIconClass(
                          state.isHideNewPassword,
                          v$.userPassword.newPassword.$error
                        )
                      "
                      @click="toggleNewPassword"
                    ></i>
                  </div>
                  <div
                    v-if="v$.userPassword.newPassword.$error"
                    class="invalid-feedback d-flex flex-column"
                  >
                    <span
                      v-if="v$.userPassword.newPassword.required.$invalid"
                      >{{ v$.userPassword.newPassword.required.$message }}</span
                    >

                    <span
                      v-if="
                        v$.userPassword.newPassword.minLength.$invalid &&
                        !v$.userPassword.newPassword.required.$invalid
                      "
                      >{{
                        v$.userPassword.newPassword.minLength.$message
                      }}</span
                    >

                    <span
                      v-if="
                        v$.userPassword.newPassword.maxLength.$invalid &&
                        !v$.userPassword.newPassword.minLength.$invalid &&
                        !v$.userPassword.newPassword.required.$invalid
                      "
                      >{{
                        v$.userPassword.newPassword.maxLength.$message
                      }}</span
                    >
                  </div>
                </div>
                <div class="col-lg-12">
                  <label for="confirmpasswordInput" class="form-label"
                    >{{ $t("t-new-password-confirm")
                    }}<span class="text-danger">*</span></label
                  >
                  <div class="form-icon right">
                    <input
                      :type="state.isHideConfirmPassword ? 'password' : 'text'"
                      class="form-control form-control-icon"
                      id="confirmpasswordInput"
                      :placeholder="$t('t-enter-new-password-confirm')"
                      v-model="state.userPassword.confirmPassword"
                      :class="{
                        'is-invalid': v$.userPassword.confirmPassword.$error,
                      }"
                      @focus="
                        () => onResetPasswordValidationField('confirmPassword')
                      "
                    />
                    <i
                      :class="
                        getIconClass(
                          state.isHideConfirmPassword,
                          v$.userPassword.confirmPassword.$error
                        )
                      "
                      @click="toggleConfirmPassword"
                    ></i>
                  </div>

                  <div
                    v-if="v$.userPassword.confirmPassword.$error"
                    class="invalid-feedback d-flex flex-column"
                  >
                    <span
                      v-if="v$.userPassword.confirmPassword.required.$invalid"
                      >{{
                        v$.userPassword.confirmPassword.required.$message
                      }}</span
                    >

                    <span
                      v-if="
                        v$.userPassword.confirmPassword.sameAs.$invalid &&
                        !v$.userPassword.confirmPassword.required.$invalid
                      "
                      >{{
                        v$.userPassword.confirmPassword.sameAs.$message
                      }}</span
                    >
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="w-100 d-flex justify-content-end gap-2">
                    <b-button type="submit" variant="primary">{{
                      $t("t-update")
                    }}</b-button>
                    <b-button @click="cancelUpdatePw" variant="success">{{
                      $t("t-cancel")
                    }}</b-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </template>
      </div>
      <div class="col-lg-9" style="flex: 4">
        <div class="card rounded-3">
          <div class="card-header">
            <h6 class="fs-16 mb-0">{{ $t("sensor.list") }}</h6>
          </div>
          <div class="table-responsive">
            <TableCommon
              ref="tableTablet"
              :columns="columns"
              :endpoint="`${ADMIN_API_PATH.ADMIN_ROOM}/sensors`"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped lang="scss">
.style-chooser:deep {
  & .vs__search {
    &::placeholder {
      color: #878a9a !important;
      font-weight: 600 !important;
      opacity: 1 !important;
    }
  }

  & .vs__search,
  .vs__selected {
    font-size: 0.85rem;
    margin-left: 4px;
    word-wrap: break-word;
    word-break: break-all;
  }

  & .vs__dropdown-toggle {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.checkbox {
  font-size: 16px;
}

.form-switch {
  padding-left: 2.5rem;
}

.custom-height {
  max-height: 57px !important;
  min-height: 24px !important;
}

.right-3 {
  right: 28px !important;
}
</style>

<style lang="scss">
.variant-primary {
  background: #5c86e0;
}

.variant-danger {
  background: #e95657;
}

.variant-dark {
  background: #2f353f;
}

.pendingClass {
  background: #e9eaed;
  color: #000000;
  border-radius: 7px;
  font-size: 11px;
}

.connectingClass {
  background: #fcf7ed;
  color: #e4b451;
  border-radius: 7px;
  font-size: 11px;
}

.errorClass {
  background: #fdeeee;
  color: #e95657;
  border-radius: 7px;
  font-size: 11px;
}

.connection_lostClass {
  background: #fdeeee;
  color: #e95657;
  border-radius: 7px;
  font-size: 11px;
}

.rebootingClass {
  background: #fcf7ed;
  color: #e4b451;
  border-radius: 7px;
  font-size: 11px;
}

.normal_startupClass {
  background: #e7f8ef;
  color: #25c06b;
  border-radius: 7px;
  font-size: 11px;
}
</style>
