<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!--    Cover Image-->
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img">
        <img
          :src="getImageURL('cover', imageCover || null)"
          class="profile-wid-img"
          alt=""
        />
        <div class="overlay-content">
          <div class="text-end p-3">
            <div class="p-0 ms-auto rounded-circle profile-photo-edit">
              <input
                ref="profileForeground"
                id="profile-foreground-img-file-input"
                @change="onChangeImageCover"
                type="file"
                accept="image/*"
                class="profile-foreground-img-file-input"
              />
              <label
                for="profile-foreground-img-file-input"
                class="profile-photo-edit btn btn-light"
              >
                <i class="ri-image-edit-line align-bottom me-1"></i> Change
                Cover
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-n5">
      <!--      Avatar-->
      <div class="col-xxl-3">
        <div class="card mt-n5">
          <div class="card-body p-4">
            <div class="text-center">
              <div
                class="profile-user position-relative d-inline-block mx-auto mb-4"
              >
                <img
                  :src="getImageURL('avatar', imageAvatar || null)"
                  class="rounded-circle avatar-xl img-thumbnail user-profile-image"
                  alt="user-profile-image"
                />
                <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                  <input
                    ref="profileImage"
                    id="profile-img-file-input"
                    @change="onChangeImageAvatar"
                    type="file"
                    accept="image/*"
                    class="profile-img-file-input"
                  />
                  <label
                    for="profile-img-file-input"
                    class="profile-photo-edit avatar-xs"
                  >
                    <span
                      class="avatar-title rounded-circle bg-light text-body"
                    >
                      <i class="bx bx-camera"></i>
                    </span>
                  </label>
                </div>
              </div>
              <h5 class="fs-16 mb-1">{{ id ? name : "" }}</h5>
              <!--              <p class="text-muted mb-0">Lead Designer / Developer</p>-->
            </div>
          </div>
        </div>
        <!--end card-->

        <div :class="[id ? '' : 'mt-n5', 'card']">
          <div class="card-body">
            <div class="d-flex align-items-center mb-3">
              <div class="flex-grow-1">
                <p class="card-title mb-0" style="font-size: 12px">
                  {{ $t("t-enable-disable") }}
                </p>
              </div>
              <div class="flex-shrink-0">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    :checked="formInput.status"
                    @change="toggleStatusMode"
                  />
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center align-items-center">
              <div class="fs-5">
                <div v-if="formInput.status">
                  <span class="d-flex align-items-center">
                    <i class="bx bx-check-circle fs-18 text-success mr-1"></i>
                    {{ $t("t-enable") }}
                  </span>
                </div>
                <div v-else>
                  <span class="d-flex align-items-center">
                    <i class="bx bx-x-circle fs-18 text-danger"></i>
                    {{ $t("t-disable") }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
      <div class="col-xxl-9">
        <div class="card mt-xxl-n5">
          <!--          Form header-->
          <div class="card-header">
            <ul
              class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link text-body"
                  :class="{ active: type == 'personalDetails' }"
                  @click="type = 'personalDetails'"
                  data-bs-toggle="tab"
                  href="javascript:void(0);"
                  role="tab"
                >
                  <i class="fas fa-home"></i>
                  {{ $t("t-personal-detail") }}
                </a>
              </li>
              <li class="nav-item" v-if="id">
                <a
                  class="nav-link text-body"
                  data-bs-toggle="tab"
                  :class="{ active: type == 'changePassword' }"
                  @click="type = 'changePassword'"
                  href="javascript:void(0);"
                  role="tab"
                >
                  <i class="far fa-user"></i>
                  {{ $t("t-change-password") }}
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body p-4">
            <div class="tab-content">
              <!--     Personal Details -->
              <div
                class="tab-pane"
                :class="{ active: type == 'personalDetails' }"
                id="personalDetails"
                role="tabpanel"
              >
                <!-- Inputs-->
                <form action="javascript:void(0);">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="mb-3 w-50 input-selection">
                        <label for="firstnameInput" class="form-label">
                          {{ $t("t-role") }}<span class="text-danger">*</span>
                        </label>
                        <!-- Selection Role -->
                        <Multiselect
                          :disabled="this.id ? true : false"
                          :placeholder="$t('t-placeholder-facility')"
                          class="multiselect-input"
                          style="padding: 0"
                          v-model="formInput.role"
                          :can-clear="false"
                          :can-deselect="false"
                          :options="optionsRole"
                          :close-on-select="true"
                        >
                        </Multiselect>
                        <!-- Selection Role -->
                      </div>
                    </div>
                    <!-- First Name-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="firstnameInput" class="form-label">
                          {{ $t("t-firstname")
                          }}<span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="firstnameInput"
                          v-model="formInput.firstName"
                          :class="{
                            'is-invalid': errorMessages.firstName.message,
                          }"
                          @focus="() => onReset('firstName')"
                          :placeholder="
                            $t(
                              't-create-update-facility-account.placeholder.first_name'
                            )
                          "
                        />
                        <p class="fs-6 text-danger mt-3">
                          {{
                            generateErrorMessage(
                              errorMessages.firstName.message,
                              errorMessages.firstName.params
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <!--end col-->
                    <!-- Last Name-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="lastnameInput" class="form-label">
                          {{ $t("t-lastname")
                          }}<span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="lastnameInput"
                          v-model="formInput.lastName"
                          :class="{
                            'is-invalid': errorMessages.lastName.message,
                          }"
                          :placeholder="
                            $t(
                              't-create-update-facility-account.placeholder.last_name'
                            )
                          "
                          @focus="() => onReset('lastName')"
                        />
                        <p class="fs-6 text-danger mt-3">
                          {{
                            generateErrorMessage(
                              errorMessages.lastName.message,
                              errorMessages.lastName.params
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <!--end col-->

                    <!-- Email-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label
                          for="emailInput"
                          class="form-label w-100 d-flex justify-content-between"
                        >
                          <div>
                            {{ $t("t-email-address") }}
                            <span class="text-danger">*</span>
                          </div>
                          <div
                            class="d-flex align-items-center"
                            v-if="
                              !id && this.formInput.role === 'FACILITY_ACCOUNT'
                            "
                          >
                            <input
                              id="autoGenerateCode"
                              class="form-check-input mt-0 mr-2"
                              v-model="autoGenerate"
                              type="checkbox"
                              value=""
                              aria-label="Checkbox input"
                              @change="changeAutoGenerate"
                            />
                            <label class="mb-0" for="autoGenerateCode">{{
                              $t("autoGenerate")
                            }}</label>
                          </div>
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="emailInput"
                          @keydown.space.prevent
                          v-model="formInput.email"
                          :disabled="disabled || autoGenerate"
                          :class="{ 'is-invalid': errorMessages.email.message }"
                          @focus="() => onReset('email')"
                          :placeholder="
                            $t(
                              't-create-update-facility-account.placeholder.email'
                            )
                          "
                        />
                        <p class="fs-6 text-danger mt-3">
                          {{
                            generateErrorMessage(
                              errorMessages.email.message,
                              errorMessages.email.params
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <!--end col-->

                    <!-- Phone number -->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="phoneNumberInput" class="form-label">
                          {{ $t("t-phone-number") }}
                        </label>
                        <input
                          type="tel"
                          class="form-control"
                          id="phoneNumberInput"
                          @keydown.space.prevent
                          v-model="formInput.phone"
                          :class="{
                            'is-invalid': errorMessages.phoneNumber.message,
                          }"
                          @input="onChangePhoneNumber($event)"
                          @focus="() => onReset('phoneNumber')"
                          :placeholder="
                            $t(
                              't-create-update-facility-account.placeholder.phone_number'
                            )
                          "
                        />
                        <p class="fs-6 text-danger mt-3">
                          {{
                            generateErrorMessage(
                              errorMessages.phoneNumber.message,
                              errorMessages.phoneNumber.params
                            )
                          }}
                        </p>
                      </div>
                    </div>

                    <!--Buttons-->
                    <div class="col-lg-12">
                      <div class="hstack gap-2 justify-content-end">
                        <button
                          :disabled="
                            facilityId === null ||
                            facilityId === '' ||
                            isLoading
                          "
                          type="submit"
                          class="btn btn-primary"
                          @click="handleUpdate"
                        >
                          <div class="d-flex justify-content-center">
                            <div class="mr-2" v-if="isLoading">
                              <Loading />
                            </div>
                            {{ id ? $t("t-update") : $t("t-register-btn") }}
                          </div>
                        </button>
                        <button
                          type="button"
                          class="btn btn-soft-success"
                          @click="debounceCancel"
                        >
                          {{ $t("t-cancel") }}
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
              </div>
              <!--end tab-pane-->

              <!-- Change Password -->
              <div
                class="tab-pane"
                id="changePassword"
                :class="{ active: type == 'changePassword' }"
                role="tabpanel"
                v-if="id"
              >
                <form action="javascript:void(0);">
                  <div class="row">
                    <!-- Email-->
                    <div class="w-100 row mb-3">
                      <div class="col-lg-6">
                        <div>
                          <label for="oldpasswordInput" class="form-label">
                            {{ $t("t-email-address") }}
                          </label>
                          <div class="form-icon right">
                            <input
                              type="email"
                              class="form-control"
                              id="emailInput"
                              @keydown.space.prevent
                              v-model="formInput.email"
                              :disabled="disabled"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="row w-100">
                      <!-- New Password-->
                      <div class="col-lg-6">
                        <div>
                          <label for="newpasswordInput" class="form-label">
                            {{ $t("t-new-password") }}
                          </label>
                          <div class="form-icon right">
                            <input
                              type="password"
                              class="form-control"
                              id="newpasswordInput"
                              :placeholder="$t('t-new-password')"
                              v-model="formChangePass.newPassword"
                              @keydown.space.prevent
                              :class="{
                                'is-invalid':
                                  errorChangePasswordMessage.newPassword
                                    .message,
                              }"
                            />
                            <i
                              class="bx bx-show"
                              :class="{
                                'me-3':
                                  errorChangePasswordMessage.newPassword
                                    .message,
                              }"
                              style="cursor: pointer"
                              id="new-password-btn"
                              @click="
                                toggleShowPassword(
                                  'newpasswordInput',
                                  'new-password-btn'
                                )
                              "
                            ></i>
                          </div>
                          <p class="fs-6 text-danger mt-3">
                            {{
                              generateErrorMessage(
                                errorChangePasswordMessage.newPassword.message,
                                errorChangePasswordMessage.newPassword.params
                              )
                            }}
                          </p>
                        </div>
                      </div>
                      <!--end col-->

                      <!-- Confirm Password-->
                      <div class="col-lg-6">
                        <div>
                          <label for="confirmpasswordInput" class="form-label">
                            {{ $t("t-confirm-new-password") }}
                          </label>
                          <div class="form-icon right">
                            <input
                              type="password"
                              class="form-control"
                              id="confirmpasswordInput"
                              :placeholder="$t('t-confirm-password')"
                              v-model="formChangePass.confirmPassword"
                              @keydown.space.prevent
                              :class="{
                                'is-invalid':
                                  errorChangePasswordMessage.confirmPassword
                                    .message,
                              }"
                            />
                            <i
                              class="bx bx-show"
                              :class="{
                                'me-3':
                                  errorChangePasswordMessage.confirmPassword
                                    .message,
                              }"
                              style="cursor: pointer"
                              id="confirm-password-btn"
                              @click="
                                toggleShowPassword(
                                  'confirmpasswordInput',
                                  'confirm-password-btn'
                                )
                              "
                            ></i>
                          </div>
                          <p class="fs-6 text-danger mt-3">
                            {{
                              generateErrorMessage(
                                errorChangePasswordMessage.confirmPassword
                                  .message,
                                errorChangePasswordMessage.confirmPassword
                                  .params
                              )
                            }}
                          </p>
                        </div>
                      </div>
                      <!--end col-->
                    </div>

                    <!--Buttons-->
                    <div class="col-lg-12">
                      <div class="hstack gap-2 justify-content-end">
                        <button
                          type="submit"
                          class="btn btn-primary"
                          @click="handleChangePass"
                        >
                          {{ $t("t-update") }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-soft-success"
                          @click="handleCancelChangePass"
                        >
                          {{ $t("t-cancel") }}
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
              </div>
              <!--end tab-pane-->
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import { facilityService } from "@/services";
import { mapGetters } from "vuex";
import { generateCode } from "@/state/helpers";
import { generateImageOss } from "../../helpers/axios/formDataRequest";
import { debounce } from "lodash";
import Loading from "@/components/Loading.vue";
import Multiselect from "@vueform/multiselect";
import { ROLES } from "@/constants";

export default {
  name: "CreateEditAccount",
  components: {
    Layout,
    PageHeader,
    Loading,
    Multiselect,
  },
  props: {
    id: { type: String, default: "" },
    formData: { type: Object },
    name: { type: String },
    errorMessages: { type: Object },
    errorChangePasswordMessage: { type: Object },
    onReset: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      facilityId: "settings/getFacilityId",
    }),
  },
  data() {
    return {
      title: "ユーザー管理",
      autoGenerate: false,
      allowedExtensions: ["image/jpeg", "image/png", "image/jpg"],
      items: [
        {
          text: this.$t("t-user-info"),
          href: "/admin/setting/facility-account",
        },
        {
          text: "ユーザー管理",
          active: true,
        },
      ],
      disabled: !!this.id,
      defaultFormInput: {
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        phone: this.formData.phone,
        email: this.formData.email,
        status: this.formData.status,
        pathAvatar: this.formData.pathAvatar,
        pathCover: this.formData.pathCover,
        facilityId: this.formData.facilityId,
        role: ROLES.FACILITY_MANAGER,
      },
      formInput: {
        firstName: this.formData.firstName || "",
        lastName: this.formData.lastName || "",
        phone: this.formData.phone || "",
        email: this.formData.email || "",
        status: this.formData.status || true,
        facilityId: this.formData.facilityId || true,
        role: ROLES.FACILITY_MANAGER,
      },
      formChangePass: {
        newPassword: "",
        confirmPassword: "",
      },
      type: "personalDetails",
      imageAvatar: null,
      imageCover: null,
      imgError: null,
      optionsFacilities: [],
      fileAvatar: null,
      fileCover: null,
      optionsRole: [
        {
          value: ROLES.FACILITY_MANAGER,
          label: "施設管理者",
        },
        {
          value: ROLES.FACILITY_ACCOUNT,
          label: "スタッフ",
        },
      ],
    };
  },
  mounted() {
    this.formInput = this.formData;
    this.imageAvatar = this.formData?.pathAvatar || "";
    this.imageCover = this.formData?.pathCover || "";
    this.getListFacilitiesOption();
  },

  methods: {
    async getListFacilitiesOption() {
      try {
        const res = await facilityService.getOptionsAllFacility({
          page: 1,
          pageSize: 500,
        });
        this.optionsFacilities = res.data;
        localStorage.setItem("listFacilities", JSON.stringify(res.data));
      } catch (e) {
        console.log(e);
      }
    },
    getImageURL(type, img) {
      let url = "";
      if (img && !img.includes("blob")) {
        url = generateImageOss(img);
      } else if (img) {
        url = img;
      } else {
        return type === "avatar"
          ? require("@/assets/images/users/defalt-user.png")
          : require("@/assets/images/profile-bg.jpg");
      }

      return url;
    },
    onChangeImageAvatar(e) {
      const files = e.target.files || e.dataTransfer.files;
      const file = files[0] || null;
      if (file.size / 1024 / 1024 > 10) {
        Swal.fire({
          title: "",
          text: this.$t("t-err-upload"),
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }
      if (!this.allowedExtensions.includes(file.type)) {
        return;
      }
      this.imageAvatar = URL.createObjectURL(file);
      this.$emit("onChangeImageAvatar", file);
    },
    updateDefault() {
      this.defaultFormInput = {
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        phone: this.formData.phone,
        email: this.formData.email,
        status: this.formData.status,
        pathAvatar: this.formData.pathAvatar,
        pathCover: this.formData.pathCover,
        facilityId: this.formData.facilityId,
      };
    },
    onChangeImageCover(e) {
      const files = e.target.files || e.dataTransfer.files;
      const file = files[0] || null;
      if (file.size / 1024 / 1024 > 10) {
        Swal.fire({
          title: "",
          text: this.$t("t-err-upload"),
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }
      if (!this.allowedExtensions.includes(file.type)) {
        return;
      }
      this.imageCover = URL.createObjectURL(file);
      this.$emit("onChangeImageCover", file);
    },
    onChangePhoneNumber(e) {
      this.formInput.phone = e.target.value.replace(/\D/g, "");
    },
    toggleShowPassword(idInput, idBtn) {
      if (idInput) {
        const input = document.getElementById(idInput);
        const btn = document.getElementById(idBtn);
        if (input.type === "password") {
          input.type = "text";
        } else input.type = "password";

        if (btn.classList.contains("bx-show")) {
          btn.classList.replace("bx-show", "bx-hide");
        } else btn.classList.replace("bx-hide", "bx-show");
      }
    },
    toggleStatusMode() {
      this.formInput.status = !this.formInput.status;
    },
    handleCancel() {
      if (!this.id) {
        this.formInput = {
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          status: true,
          role: this.formInput.role,
        };
        this.imageAvatar = null;
        this.onChangeImageAvatar(true);
        this.imageCover = null;
      } else {
        this.updateDefault();
        this.formInput = { ...this.defaultFormInput };
        this.imageAvatar = this.defaultFormInput?.pathAvatar;
        this.imageCover = this.defaultFormInput?.pathCover;
      }
      this.$emit("onChangeImageAvatar", null);
      this.$emit("onChangeImageCover", null);
      this.$refs.profileImage.value = null;
      this.$refs.profileForeground.value = null;
    },
    debounceCancel: debounce(function () {
      this.handleCancel();
    }, 500),

    handleCancelChangePass() {
      this.formChangePass = {
        newPassword: "",
        confirmPassword: "",
      };
      this.imageAvatar = this.formData?.pathAvatar;
      this.formInput.status = this.formData.status;
      this.$emit("onChangeImageAvatar", null);
      this.imageCover = this.formData?.pathCover;
      this.$emit("onChangeImageCover", null);
      this.$refs.profileImage.value = null;
      this.$refs.profileForeground.value = null;
    },
    async handleUpdate() {
      if (this.facilityId === null || this.facilityId === "") {
        return;
      }
      this.formInput.facilityId = this.facilityId;
      this.$emit("onCreateAndUpdateAccount", this.formInput);
    },
    handleChangePass() {
      const params = {
        formChangePass: this.formChangePass,
        formUpdateUser: this.defaultFormInput,
      };
      this.$emit("onChangePassword", params);
    },
    generateErrorMessage(msg, field) {
      return msg ? this.$t(msg, field) : "";
    },
    async changeAutoGenerate() {
      if (this.autoGenerate) {
        try {
          const facility = await facilityService.getFacility(this.facilityId);
          const { facilityIdStr } = facility;
          const codeGenerate = generateCode();
          this.formInput.email = `${codeGenerate}@${facilityIdStr}.tokyo-saunist.com`;
        } catch (e) {
          console.log(e, "error");
        }
      } else {
        this.formInput.email = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.multiselect-input ::v-deep {
  .multiselect-wrapper {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    min-height: calc(
      var(--ms-border-width, 1px) * 2 + var(--ms-font-size, 1rem) *
        var(--ms-line-height, 1.375) + var(--ms-py, 0.5rem) * 2 - 2px
    );
    outline: none;
    position: relative;
    width: 100%;
  }
}

.form-control:disabled {
  background: #c5bebe !important;
  color: #707070 !important;
}

.input-selection {
  padding-right: 13px;
}

[data-layout-mode="dark"] {
  .form-control:disabled {
    background: #212529 !important;
    color: #ced4da !important;
  }
}

.is-disabled {
  background: var(--ms-bg-disabled, #c5bebe) !important;
  cursor: default;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
