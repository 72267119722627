import {
  alphaNum,
  helpers,
  maxLength,
  minLength,
  required,
  numeric,
  minValue,
  maxValue,
} from "@vuelidate/validators";

export const STORAGE_KEY = {
  USER: "user",
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  REMEMBER_USER: "remember_user",
  REMEMBER_USERNAME: "remember_username",
  REMEMBER_PASSWORD: "remember_password",
  AUTO_REFRESH: "second",
};

export const HEADER_KEY = {
  AUTHORIZATION: "Authorization",
  BEARER: "Bearer",
};

export const ADMIN_API_PATH = {
  ADMIN_AUTH: "/web/auth",
  ADMIN_USER: "/web/users",
  ADMIN_USER_WITHOUT_FM: "/web/users/withoutFM",
  ADMIN_FACILITIES: "/web/facilities",
  ADMIN_ROOM: "/web/rooms",
  ADMIN_CATEGORIES: "/web/categories",
  ADMIN_SERVICES: "/web/services",
  ADMIN_FACILITIES_SERVICES: "/web/facility_services",
  ADMIN_SENSOR_LOGS: "/web/sensor_logs",
  ADMIN_SENSOR_LOGS_PUBLIC: "/sensor_logs",
  ADMIN_ADS: "/web/advertises",
  ADMIN_SYSTEM: "/system-config",
  ADMIN_SNS: "/web/posts",
  ADMIN_COUPON: "/web/coupons",
  ADMIN_STAFF: "/staff-registration",
  ADMIN_PUBLIC_API: "/web/public-api",
  ADMIN_PLAY_LIST: "/web/playlist",
  ADMIN_WHITELIST: "/whitelist",
  ADMIN_HOLIDAY: "/web/holiday",
};

export const API_PATH = {
  ROOM: "/rooms",
  MORE_ROOM: "/rooms/moreRoom",
  FACILITIES: "/facilities",
  UPLOAD_IMAGES: "/upload-images",
  PASSWORD: "passwd",
  NOTIFICATION: "/notifications",
  ALERT: "/alert-facilities",
};

export const DEFAULT_PARAMS = {
  PAGE: 1,
  PAGE_SIZE: 10,
};

export const REGEX = {
  ALPHABET_CHARACTER_ONLY: /[a-zA-Z]/i,
  PHONE_NUMBER_CHARACTER: /[0-9-]/i,
  SPECIAL_CHARACTERS: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/,
  EMAIL_VALIDATE:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  // NUMBER_HAFTWIDTH: /[0-9](-)[0-9]+$/,
  NUMBER_HAFTWIDTH: /^([0-9-]*)$/,
  URL: /[-a-zA-Z0-9@:%._,~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_,.~#?&]*)$/,
};

export const USER_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const USER_ACTION = {
  CREATE_UPDATE_ACCOUNT: "CREATE_UPDATE_ACCOUNT",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
};

export const ROLES = {
  ADMIN: "ADMIN",
  FACILITY_MANAGER: "FACILITY_MANAGER",
  FACILITY_ACCOUNT: "FACILITY_ACCOUNT",
  TABLET: "TABLET",
};

export const ROLES_JP = {
  ADMIN: "ADMIN",
  FACILITY_MANAGER: "施設管理者",
  FACILITY_ACCOUNT: "スタッフ",
  TABLET: "TABLET",
};
export const ORIGIN_URL = {
  ADMIN: "/admin/setting/user-management-by-admin",
  FACILITY_MANAGER: "/admin",
  FACILITY_ACCOUNT: "/admin",
};

export const CONTENT_TYPE = {
  JSON: "application/json",
  JAVASCRIPT: "application/javascript",
  XML: "application/xml",
  ZIP: "application/zip",
  PDF: "application/pdf",
  SQL: "application/sql",
  MPEG: "audio/mpeg",
  OGG: "audio/ogg",
  FORMDATA: "multipart/form-data",
  CSS: "text/css",
  HTML: "text/html",
  PNG: "image/png",
  JPEG: "image/jpeg",
  GIF: "image/gif",
};

export const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const REQUEST_TIMEOUT = 300000;

export const MAX_FILE_SIZE = 10;

export const RULE_FACILITY = {
  name: { required },
  cost: { required, alphaNum, maxLength: maxLength(10) },
  openTime: { required },
  closeTime: { required },
  address1: { required, maxLength: maxLength(255) },
  address2: { required, maxLength: maxLength(255) },
  address3: { maxLength: maxLength(255) },
  address4: { required, maxLength: maxLength(255) },
  description: { maxLength: maxLength(255) },
  costStr: { maxLength: maxLength(255) },
  twitter: { maxLength: maxLength(255) },
  instagram: { maxLength: maxLength(255) },
  website: { maxLength: maxLength(255) },
  phone: {
    phoneNumber: helpers.regex(REGEX.NUMBER_HAFTWIDTH),
    maxLength: maxLength(17),
    minLength: minLength(10),
  },
};
export const RULE_NOTIFICATION = {
  title: { required },
  sendTo: { required },
};

export const EXPORT_ADS_HISTORY = {
  range: { required },
  facilityId: { required },
  type: { required },
};

export const RULE_ALERT = {
  startTime: { required },
  endTime: { required },
  person: { required },
  minutes: { required, numeric, min: minValue(30), max: maxValue(480) },
};

export const RULE_ADS = {
  name: { required },
  appearanceTime: { required },
  url: { required },
};

const location = helpers.regex(/^[0-9\d.\d,\s,]*$/);
export const RULE_LOCATION = {
  mapInfomation: {
    location,
    required,
  },
};

const regexHaftWidth = helpers.regex(/[0-9]/i);
export const RULE_POST_CODE = {
  first: {
    required,
    minLength: minLength(3),
    maxLength: maxLength(3),
    haftwidth: regexHaftWidth,
  },
  last: {
    required,
    minLength: minLength(4),
    maxLength: maxLength(4),
    haftwidth: regexHaftWidth,
  },
};

export const SENSOR_STATUS = {
  NONE_STATUS: "",
  NORMAL_STARTUP: "normal_startup",
  CONNECTING: "connecting",
  REBOOTING: "rebooting",
  CONNECTION_LOST: "connection_lost",
  ERROR: "error",
  PENDING: "pending",
};

export const FacilitiesRequestStatus = {
  WAITING: "WAITING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  CANCELED: "CANCELED",
};
export const FacilitiesRequestType = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  CLOSE: "CLOSE",
};

export const ColorStatus = {
  WAITING: "warning",
  APPROVED: "success",
  REJECTED: "danger",
  CANCELED: "secondary",
};

export const ColorType = {
  CREATE: "success",
  UPDATE: "warning",
  CLOSE: "danger",
};

export const ADS_TYPE = {
  VIDEO: "動画",
  IMAGE: "画像",
};

export const ROOM_TYPE = {
  SAUNA_ROOM_FEMALE: "女湯",
  SAUNA_ROOM_MALE: "男湯",
};
export const URL_OSS = "https://qti-image-dev.oss-ap-northeast-1.aliyuncs.com/";

export const ROUTE = {
  ALL: [
    "user-management-by-admin",
    "admin-create-user",
    "admin-update-user",
    "facility-info-management",
    "accounts",
    "create-account",
    "edit-account",
    "admin-facility-list",
    "upload-facility-list",
    "facility-update-management",
    "advertisement-management",
    "advertisement-management-create",
    "advertisement-management-update",
    "advertisement-history",
    "facility-create-management",
    "system-management",
    "facilities-management-by-admin",
    "admin-upload-images",
    "shift-form",
    "sns-setting",
    "facility-info-management",
    "publicApi-management",
    "play-discs-management",
    "record-chart",
  ],
  FM: [
    "admin-facility-list",
    "facility-create-management",
    "facility-update-management",
    "facilities-management-by-admin",
    "facility-update-app-management",
  ],
  ADS: [
    "advertisement-management",
    "play-discs-management",
    "play-discs-create",
    "play-discs-update",
    "advertisement-history",
  ],
  SETTING: [
    "admin-upload-images",
    "publicApi-management",
    "system-management",
    "setting-holidays",
  ],
};

export const SensorStatus = {
  NONE_STATUS: "pending",
  NORMAL_STARTUP: "normal_startup",
  CONNECTING: "connecting",
  REBOOTING: "rebooting",
  CONNECTION_LOST: "connection_lost",
  ERROR: "error",
};
