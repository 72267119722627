<script>
import {
  layoutComputed
} from "@/state/helpers";
import { mapGetters, useStore } from "vuex";
import { ROLES, ROUTE } from "@/constants";
import { computed } from 'vue';

export default {
  setup() {
    const store = useStore()
    const facilityId = computed(() => store.getters['settings/getFacilityId'])
    return {
      facilityId,
    }
  },
  components: {
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
      collapse: true,
      defineRoute: ROUTE
    };
  },
  computed: {
    ...layoutComputed,
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {};
      },
    },
    roles() {
      return ROLES;
    },
    ...mapGetters('auth', ['currentUser']),
    getInfoClass() {
      const { name } = this.$route;
      if (!this.collapse && name === 'facility-info-management') {
        return 'router-link-active router-link-extract-active active'
      }

      return ''
    },
    getCollapseClass() {
      const collapse = this.collapse ? '' : 'show'
      return `collapse menu-dropdown ${collapse}`
    },

    getDashboardClass() {
      return this.$route.name === 'default' ? 'router-link-active router-link-extract-active active' : ''
    },
  },

  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse");
      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation();
          let closestCollapse = collapse.parentElement.closest(".collapse");
          if (closestCollapse) {
            let siblingCollapses =
              closestCollapse.querySelectorAll(".collapse");
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                let aChild = siblingCollapse.parentNode.firstChild;
                if (aChild.hasAttribute("aria-expanded")) {
                  aChild.setAttribute("aria-expanded", "false");
                }
                siblingCollapse.classList.remove("show");
              }
            });
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = [];
              let sibling = elem.parentNode.firstChild;
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
              }
              return siblings;
            };
            let siblings = getSiblings(collapse.parentElement);
            siblings.forEach((item) => {
              if (item.childNodes.length > 2)
                item.firstElementChild.setAttribute("aria-expanded", "false");
              let ids = item.querySelectorAll("*[id]");
              ids.forEach((item1) => {
                let aChild = item1.parentNode.firstChild;
                if (aChild.hasAttribute("aria-expanded")) {
                  aChild.setAttribute("aria-expanded", "false");
                }
                item1.classList.remove("show");
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a");

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false");
                  });
                }
              });
            });
          }
        });

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation();
          let childCollapses = collapse.querySelectorAll(".collapse");
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse;
            childCollapseInstance.hide();
          });
        });
      });
    }
  },

  methods: {
    getClass(routerName) {
      const { name } = this.$route;
      if (routerName.findIndex(item => item === name) >= 0) {
        return 'router-link-active router-link-extract-active active'
      }

      return ''
    },

    setExpended(routerName, type = '') {
      const { name } = this.$route;
      const find = routerName.findIndex(item => item === name)
      if (type === '') {
        return find >= 0;
      }
      else {
        return find >= 0 ? 'collapse menu-dropdown show' : 'collapse menu-dropdown';
      }
    },

    onRoutechange(route) {
      this.collapse = route.name !== 'default' ? !this.defineRoute.ALL.includes(route.name) : true;
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add("active");
              parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
              if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                  parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add(
                    "active");
              }
            }
          }
        }
      }, 0);
    },
    getRouteName(currentUser) {
      // check role -> get route name
      // if(currentUser.role === ROLES.FACILITY_MANAGER) {
      //   return 'facility-manager-user-list'
      // }

      if (currentUser && (currentUser.role === ROLES.FACILITY_MANAGER || currentUser.role === ROLES.FACILITY_ACCOUNT)) {
        return 'accounts'
      }

      return 'user-management-by-admin'
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <div id="two-column-menu"></div>

    <ul v-if="currentUser && currentUser.role == roles.ADMIN" class="navbar-nav h-100" id="navbar-nav">
      <li class="menu-title fs-14 font-weight-bold">
        <span data-key="t-menu"> {{ $t("t-menu") }}</span>
      </li>
      <li class="nav-item">
        <router-link class="nav-link menu-link" :class="getClass(['user-management-by-admin', 'admin-create-user', 'admin-update-user'])"
          :to="{ name: getRouteName(currentUser) }" data-key="t-user-management">
          <i class="ri-user-3-fill"></i>
          <span>{{ $t("t-user-management") }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <a class="nav-link menu-link" href="#sidebarApps" data-bs-toggle="collapse" role="button"
          :aria-expanded="setExpended(defineRoute.FM)" aria-controls="sidebarApps">
          <i class="bx bxs-institution"></i>
          <span data-key="t-apps"> {{ $t("facility.management") }} </span>
        </a>
        <div
          :class="setExpended(defineRoute.FM, 'class')"
          id="sidebarApps">
          <ul class="nav nav-sm flex-column">
            <li class="nav-item">
              <router-link
                :class="getClass(['admin-facility-list', 'facility-update-management', 'upload-facility-list', 'facility-create-management'])"
                :to="{ name: 'admin-facility-list' }" class="nav-link" data-key="admin-facility-list">
                {{ $t("facility.facility-info-manager") }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :class="getClass(['facilities-management-by-admin', 'facility-update-app-management'])"
                :to="{ name: 'facilities-management-by-admin' }" class="nav-link"
                data-key="facilities-management-by-admin">
                {{ $t('facility.app-management') }}
              </router-link>
            </li>
          </ul>
        </div>
      </li>

      <li class="nav-item">
        <router-link :class="getClass(['user-management-by-admin-tablet'])"
          :to="{ name: 'user-management-by-admin-tablet' }" class="nav-link menu-link"
          data-key="user-management-by-admin">
          <i class="bx bxs-chip"></i>
          <span>{{ $t('sensor.management') }}</span>
        </router-link>
      </li>

      <li class="nav-item">
        <a class="nav-link menu-link" href="#sidebarAd34" data-bs-toggle="collapse" role="button"
          aria-controls="sidebarAd34" :aria-expanded="setExpended(defineRoute.ADS)">
          <i class="bx bx-movie-play"></i>
          <span data-key="t-ads-management"> {{ $t('t-ads-management') }} </span>
        </a>
        <div id="sidebarAd34" :class="setExpended(defineRoute.ADS, 'class')">
          <ul class="nav nav-sm flex-column">
            <li class="nav-item">
              <router-link :class="getClass(['advertisement-management'])" :to="{ name: 'advertisement-management' }"
                class="nav-link" data-key="t-ads-name">
                {{ $t('t-ads-name') }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :class="getClass(['play-discs-management', 'play-discs-create', 'play-discs-update'])"
                :to="{ name: 'play-discs-management' }" class="nav-link" data-key="playDiscs.play-discs-management">
                {{ $t('playDiscs.play-discs-management') }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :class="getClass(['advertisement-history'])" :to="{ name: 'advertisement-history' }"
                class="nav-link" data-key="advertising.history">
                {{ $t('advertising.history') }}
              </router-link>

            </li>
          </ul>
        </div>
      </li>

      <li class="nav-item">
        <router-link :class="getClass(['notification-management'])" :to="{ name: 'notification-management' }"
          class="nav-link menu-link" data-key="user-management-by-admin">
          <i class="bx bxs-notification"></i>
          <span>{{ $t('pushNotification.notification') }}</span>
        </router-link>
      </li>

      <li class="nav-item">
        <a class="nav-link menu-link" href="#sidebarSettingApp" data-bs-toggle="collapse" role="button"
          aria-controls="sidebarSettingApp" :aria-expanded="setExpended(defineRoute.SETTING)">
          <i class="bx bxs-cog"></i>
          <span data-key="t-setting"> {{ $t('t-setting_') }} </span>
        </a>
        <div id="sidebarSettingApp" :class="setExpended(defineRoute.SETTING, 'class')">
          <ul class="nav nav-sm flex-column">
            <li class="nav-item">
              <router-link :class="getClass(['admin-upload-images'])" :to="{ name: 'admin-upload-images' }"
                class="nav-link" data-key="admin-upload-images">
                {{ $t('t-media-upload') }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :class="getClass(['system-management'])" :to="{ name: 'system-management' }" class="nav-link"
                data-key="system-management">
                {{ $t('t-system-setting') }}
              </router-link>

            </li>
            <li class="nav-item">
              <router-link :class="getClass(['publicApi-management'])" :to="{ name: 'publicApi-management' }"
                class="nav-link" data-key="publicApi-management">
                {{ $t('publicAPI.menuName') }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :class="getClass(['setting-holidays'])" :to="{ name: 'setting-holidays' }"
                           class="nav-link" data-key="setting-holidays">
                {{ $t('t-holidays') }}
              </router-link>
            </li>
          </ul>
        </div>
      </li>
    </ul>

    <ul v-else class="navbar-nav h-100" id="navbar-nav">
      <li class="menu-title fs-14 font-weight-bold">
        <span data-key="t-menu"> {{ $t("t-menu") }}</span>
      </li>
      <li class="nav-item">
        <router-link :class="getDashboardClass" class="nav-link menu-link" :to="{ name: 'default' }">
          <i class="bx bxs-dashboard"></i>
          <span data-key="t-dashboards">{{ $t('t-dashboard') }}</span>
        </router-link>
      </li>
      <!-- end Dashboard Menu -->
      <li class="nav-item">
        <router-link :class="getClass(['accounts', 'create-account'])" :to="{ name: 'accounts' }" class="nav-link menu-link"
          data-key="accounts">
          <i class="bx bxs-user"></i>
          <span>ユーザー管理</span>
        </router-link>
      </li>

      <li class="nav-item">
        <a class="nav-link menu-link" href="#sidebarStaff" data-bs-toggle="collapse" role="button"
          :aria-expanded="setExpended(['shift-form', 'record-chart'])" aria-controls="sidebarStaff">
          <i class="bx bxs-calendar-star"></i>
          <span>{{ $t("t-timesheet") }}</span>
        </a>
        <div id="sidebarStaff"
          :class="setExpended(['shift-form', 'record-chart'], 'class')">
          <ul class="nav nav-sm flex-column">
            <li class="nav-item">
              <router-link :class="getClass(['shift-form'])" :to="{ name: 'shift-form' }" class="nav-link menu-link"
                data-key="timesheet.staff-form">
                <span>{{ $t("timesheet.staff-form") }}</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :class="getClass(['record-chart'])" :to="{ name: 'record-chart' }" class="nav-link menu-link"
                data-key="timesheet.staff-chart">
                <span>{{ $t("timesheet.staff-chart") }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </li>

      <li class="nav-item">
        <router-link :to="{ name: 'coupon_list' }"
          :class="getClass(['coupon_list', 'coupon_view', 'coupon_update', 'coupon_create'])" class="nav-link menu-link"
          data-key="coupon.management">
          <i class="bx bxs-coupon"></i>
          <span>{{ $t('coupon.management') }}</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="currentUser && currentUser.role == roles.FACILITY_MANAGER">
        <a class="nav-link menu-link" href="#sidebarAd34" data-bs-toggle="collapse" role="button"
          aria-controls="sidebarAd34" :aria-expanded="setExpended(defineRoute.ADS)">
          <i class="bx bx-movie-play"></i>
          <span data-key="t-ads-management"> {{ $t('t-ads-management') }} </span>
        </a>
        <div id="sidebarAd34" :class="setExpended(defineRoute.ADS, 'class')">
          <ul class="nav nav-sm flex-column">
            <li class="nav-item">
              <router-link :class="getClass(['advertisement-management'])" :to="{ name: 'advertisement-management' }"
                class="nav-link" data-key="t-ads-name">
                {{ $t('t-ads-name') }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :class="getClass(['play-discs-management', 'play-discs-create', 'play-discs-update'])"
                :to="{ name: 'play-discs-management' }" class="nav-link" data-key="playDiscs.play-discs-management">
                {{ $t('playDiscs.play-discs-management') }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :class="getClass(['advertisement-history'])" :to="{ name: 'advertisement-history' }"
                class="nav-link" data-key="advertising.history">
                {{ $t('advertising.history') }}
              </router-link>

            </li>
          </ul>
        </div>
      </li>

      <li class="nav-item">
        <router-link :class="getClass(['notification-management'])" :to="{ name: 'notification-management' }"
          class="nav-link menu-link" data-key="user-management-by-admin">
          <i class="bx bxs-notification"></i>
          <span>{{ $t('pushNotification.notification') }}</span>
        </router-link>
      </li>

      <li class="nav-item">
        <a class="nav-link menu-link" href="#sidebarSettingApp" data-bs-toggle="collapse" role="button"
          :aria-expanded="setExpended(['facility-info-management', 'sns-setting'])" aria-controls="sidebarApps">
          <i class="bx bxs-cog"></i>
          <span data-key="t-setting"> 設定 </span>
        </a>
        <div id="sidebarSettingApp"
          :class="setExpended(['facility-info-management', 'sns-setting', 'setting-alert'], 'class')">
          <ul class="nav nav-sm flex-column">
            <li class="nav-item">
              <router-link :class="getClass(['facility-info-management'])" :to="{ name: 'facility-info-management' }"
                class="nav-link custom-abc" data-key="t-facility-info-management">
                {{ $t("t-facility-info-management") }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :class="getClass(['setting-alert'])" :to="{ name: 'setting-alert' }"
                class="nav-link custom-abc" data-key="t-facility-info-management">
                {{ $t("t-system-setting") }}
              </router-link>
            </li>

            <li v-if="facilityId" class="nav-item">
              <router-link :class="getClass(['sns-setting'])" :to="{ name: 'sns-setting' }" class="nav-link"
                data-key="sns.management">
                {{ $t("sns.management") }}
              </router-link>
            </li>
          </ul>
        </div>
      </li>

      <li class="nav-item">
        <a class="nav-link menu-link" role="button" href="https://www.tokyo-saunist.com/analytics-manual/" target="_blank">
          <i class="bx bx-help-circle"></i>
          <span data-key="t-fmHelpMenu"> {{ $t('t-fmHelpMenu') }} </span>
        </a>
      </li>
    </ul>
  </div>
  <!-- Sidebar -->
</template>
