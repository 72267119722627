import { ADMIN_API_PATH } from "../constants";
import axios from "../helpers/axios";

/**
 * Create ads
 * @param {*} advertisement
 * @returns Promise
 */
const createAds = async (advertisement) => {
  return await axios.post(ADMIN_API_PATH.ADMIN_ADS, advertisement);
};

/**
 * Get ads information
 * @param {*} adsId
 */
const getAdminAds = async (adsId) => {
  try {
    const response = await axios.get(`${ADMIN_API_PATH.ADMIN_ADS}/${adsId}`);
    return response;
  } catch (err) {
    console.log(
      `get ads info`,
      `${ADMIN_API_PATH.ADMIN_ADS}/${adsId}`,
      JSON.stringify(err)
    );
    return null;
  }
};

/**
 * Get ads list
 * @param {*} paginate
 */
const getAdsList = async (paginate) => {
  try {
    const query = new URLSearchParams(paginate);
    const response = await axios.get(`${ADMIN_API_PATH.ADMIN_ADS}?${query}`);
    return response;
  } catch (err) {
    //console.log(`get ads`,`${ADMIN_API_PATH.ADMIN_ADS}`, JSON.stringify(err));
    return null;
  }
};

/**
 * Update ads
 * @param {*} adsId
 * @param {*} ads
 * @returns Promise
 */
const updateAds = async (adsId, ads) => {
  try {
    const response = await axios.put(
      `${ADMIN_API_PATH.ADMIN_ADS}/${adsId}`,
      ads
    );
    return response;
  } catch (err) {
    console.log(
      `update ads`,
      `${ADMIN_API_PATH.ADMIN_ADS}/${adsId}`,
      JSON.stringify(err)
    );
    throw err.response;
  }
};

/**
 * Update ads status
 * @param {*} adsId
 * @param {Object} adsStatus
 * @returns Promise
 */
const updateAdsStatus = async (adsId, adsStatus) => {
  try {
    const response = await axios.put(
      `${ADMIN_API_PATH.ADMIN_ADS}/${adsId}/status`,
      adsStatus
    );
    return response;
  } catch (err) {
    console.log(
      `updateAdsStatus`,
      `${ADMIN_API_PATH.ADMIN_ADS}/${adsId}/status`,
      JSON.stringify(err)
    );
    throw err.response;
  }
};

/**
 * Get user information
 * @param {*} adsId
 */
const getAds = async (adsId) => {
  try {
    const response = await axios.get(`${ADMIN_API_PATH.ADMIN_ADS}/${adsId}`);
    return response;
  } catch (err) {
    console.log(
      `getUser`,
      `${ADMIN_API_PATH.ADMIN_ADS}/${adsId}`,
      JSON.stringify(err)
    );
    return null;
  }
};

const getDailyList = async (paginate) => {
  try {
    const query = new URLSearchParams(paginate);
    return await axios.get(`${ADMIN_API_PATH.ADMIN_ADS}/v2/daily?${query}`);
  } catch (err) {
    console.log(
      `getDailyList`,
      `${ADMIN_API_PATH.ADMIN_ADS}/daily`,
      JSON.stringify(err)
    );
    return null;
  }
};

/**
 * @param {Object} params
 * Get All ads
 * @returns
 */
const getAllAds = async () => {
  const adUrl = `${ADMIN_API_PATH.ADMIN_ADS}/ads/all`;
  try {
    return await axios.get(url);
  } catch (err) {
    console.log(`getAllAds`, `${adUrl}`, JSON.stringify(err));
    return null;
  }
};

/**
 * Update order
 * @param {Object} orderListObj
 * @returns
 */
const updateOrder = async (orderListObj) => {
  try {
    return await axios.put(
      `${ADMIN_API_PATH.ADMIN_ADS}/orders/update`,
      orderListObj
    );
  } catch (err) {
    console.log(
      `updateOrder`,
      `${ADMIN_API_PATH.ADMIN_ADS}/orders/update`,
      JSON.stringify(err)
    );
    throw err.response;
  }
};

/**
 * Update batch status
 * @param {Object} data
 * @returns
 */
const updateBatchStatus = async (data) => {
  try {
    return await axios.put(
      `${ADMIN_API_PATH.ADMIN_ADS}/ads/batch/status`,
      data
    );
  } catch (err) {
    console.log(
      `updateBatchStatus`,
      `${ADMIN_API_PATH.ADMIN_ADS}/ads/batch/status`,
      JSON.stringify(err)
    );
    throw err.response;
  }
};

/**
 * Delete a ads
 * @param {*} id
 * @returns Promise
 */
const deleteAds = async (id) => {
  try {
    return await axios.delete(`${ADMIN_API_PATH.ADMIN_ADS}/${id}`);
  } catch (err) {
    console.log(
      `delete ads`,
      `${ADMIN_API_PATH.ADMIN_ADS}/${id}`,
      JSON.stringify(err)
    );
    throw err.response;
  }
};

/**
 * Get ads history list
 * @param {*} paginate
 * @returns Promise
 */
const getAdsHistoryList = async (paginate) => {
  try {
    const query = new URLSearchParams(paginate);
    return await axios.get(`${ADMIN_API_PATH.ADMIN_ADS}/v2/histories?${query}`);
  } catch (err) {
    //console.log(`getAdsHistory`, `${ADMIN_API_PATH.ADMIN_ADS}/histories`, JSON.stringify(err));
    return null;
  }
};

const exportExternalLog = async (payload) => {
  const query = new URLSearchParams(payload);
  return await axios.get(
    `${ADMIN_API_PATH.ADMIN_ADS}/external/export?${query}`
  );
};

const exportInternal = async (payload) => {
  const query = new URLSearchParams(payload);
  return await axios.get(
    `${ADMIN_API_PATH.ADMIN_ADS}/internal/export?${query}`
  );
};

const getPlaylistOptions = async () => {
  return axios.get(`${ADMIN_API_PATH.ADMIN_PLAY_LIST}/all`);
};

const getFacilitiesOptionsPlayList = (params = { facType: "ALL" }) => {
  return axios.get(`${ADMIN_API_PATH.ADMIN_FACILITIES}/option`, { params });
};

const getFacilitiesOptionsPlayListNew = (facilityId) => {
  return axios.get(
    `${ADMIN_API_PATH.ADMIN_FACILITIES}/option-new${
      facilityId ? `?facilityId=${facilityId}` : ""
    }`
  );
};
const getAdsOptionsPlaylist = (facilityId, createdByFacilityId) => {
  return axios.get(
    `${ADMIN_API_PATH.ADMIN_ADS}/ads/all?type=all&page=1&pageSize=500${
      facilityId ? `&facilityId=${facilityId}` : ""
    }${
      createdByFacilityId ? `&createdByFacilityId=${createdByFacilityId}` : ""
    }`
  );
};

const createPlaylist = (payload) => {
  return axios.post(`${ADMIN_API_PATH.ADMIN_PLAY_LIST}`, payload);
};

const updatePlaylist = (id, payload) => {
  return axios.put(`${ADMIN_API_PATH.ADMIN_PLAY_LIST}/${id}`, payload);
};

const getDetailPlaylist = (id, facUuid) => {
  return axios.get(
    `${ADMIN_API_PATH.ADMIN_PLAY_LIST}/${id}?facUuid=${facUuid}`
  );
};

const deletePlaylist = (id) => {
  return axios.delete(`${ADMIN_API_PATH.ADMIN_PLAY_LIST}/${id}`);
};
export const adsService = {
  createAds,
  getAdsList,
  getAdminAds,
  deleteAds,
  updateAds,
  getAdsHistoryList,
  getAds,
  updateAdsStatus,
  getDailyList,
  getAllAds,
  updateBatchStatus,
  updateOrder,
  exportExternalLog,
  exportInternal,
  getPlaylistOptions,
  getFacilitiesOptionsPlayList,
  getAdsOptionsPlaylist,
  createPlaylist,
  getDetailPlaylist,
  updatePlaylist,
  deletePlaylist,
  getFacilitiesOptionsPlayListNew,
};
