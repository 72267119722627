<script>
import Swal from "sweetalert2";
import "@fullcalendar/core/vdom";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";

import FullCalendar from "@fullcalendar/vue3";

import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import vSelect from "vue-select";
import { useStore, mapGetters } from "vuex";
import { userService, staffService } from "@/services";
import { convertMinutesToTimes } from "@/helpers/moment";
import moment from "moment";
import { MAX_FILE_SIZE } from "@/constants";
import i18n from "@/i18n";

import { reactive, onMounted, computed, watch, ref } from "vue";
import { debounce } from "lodash";
import { ROLES } from "../../constants";
export default {
  setup() {
    const dropZoneRef = ref(null);
    const textRef = ref("");
    const uploadRef = ref(null);
    const state = reactive({
      value: null,
      data: [],
      openModal: false,
      query: {
        page: 1,
        pageSize: 1000,
        facilityId: "",
        role: "FACILITY_ACCOUNT",
      },
    });

    const openUploadModal = () => {
      state.openModal = true;
      dropZoneRef.value = null;
      textRef.value = "";
    };

    const store = useStore();
    const facilityId = computed(() => store.getters["settings/getFacilityId"]);
    const currentUser = computed(() => store.getters["auth/currentUser"]);
    const disabled = computed(
      () =>
        (currentUser.value &&
          currentUser.value.role === ROLES.FACILITY_ACCOUNT) ||
        !currentUser.value
    );

    const getUserList = async (facilityId = "") => {
      if (!facilityId) return;
      const response = await userService.getUsersWithoutFM({
        ...state.query,
        facilityId,
      });
      state.data = response.data
        .filter((f) => f.status !== "INACTIVE")
        .map((item) => ({
          value: item.id,
          label: `${item.name}(${item.userIdStr || ""})`,
        }));
    };

    onMounted(() => {
      getUserList(facilityId.value);
    });

    watch(facilityId, () => {
      getUserList(facilityId.value);
    });

    return {
      v$: useVuelidate(),
      state,
      openUploadModal,
      dropZoneRef,
      textRef,
      uploadRef,
      disabled,
      currentUser,
    };
  },
  validations: {
    params: {
      userId: {
        required: helpers.withMessage(
          i18n.global.t("field_required", {
            field: i18n.global.t("timesheet.user"),
          }),
          required
        ),
      },
      day: {
        required: helpers.withMessage(
          i18n.global.t("field_required", {
            field: i18n.global.t("timesheet.date"),
          }),
          required
        ),
      },
      startTime: {
        required: helpers.withMessage(
          i18n.global.t("field_required", {
            field: i18n.global.t("timesheet.time-picker"),
          }),
          required
        ),
      },
      endTime: {
        required: helpers.withMessage(
          i18n.global.t("field_required", {
            field: i18n.global.t("timesheet.time-picker"),
          }),
          required
        ),
        isGreater: {
          $message() {
            return i18n.global.t("timesheet.wrong-time-range");
          },
          $validator(value) {
            return !this.params.startTime || value > this.params.startTime;
          },
        },
      },
    },
    editevent: {
      userId: {
        required: helpers.withMessage(
          i18n.global.t("field_required", {
            field: i18n.global.t("timesheet.user"),
          }),
          required
        ),
      },
      day: {
        required: helpers.withMessage(
          i18n.global.t("field_required", {
            field: i18n.global.t("timesheet.date"),
          }),
          required
        ),
      },
      startTime: {
        required: helpers.withMessage(
          i18n.global.t("field_required", {
            field: i18n.global.t("timesheet.time-picker"),
          }),
          required
        ),
      },
      endTime: {
        required: helpers.withMessage(
          i18n.global.t("field_required", {
            field: i18n.global.t("timesheet.time-picker"),
          }),
          required
        ),
        isGreater: {
          $message() {
            return i18n.global.t("timesheet.wrong-time-range");
          },
          $validator(value) {
            return (
              !this.editevent.startTime || value > this.editevent.startTime
            );
          },
        },
      },
    },
  },
  data() {
    return {
      config: {
        altFormat: "Y-m-d",
        altInput: false,
      },
      loading: false,
      search: "",
      searching: false,
      title: this.$t("t-timesheet"),
      dateTime: null,
      data: [],
      query: {
        page: 1,
        pageSize: 10,
        facilityId: "",
      },
      items: [
        {
          text: this.$t("t-timesheet"),
          active: true,
        },
        {
          text: this.$t("t-timesheet"),
          active: true,
        },
      ],
      currentDate: moment(new Date()).subtract(1, "M").toDate(),
      calendarOptions: {
        events: [],
        locale: "ja",
        timeZone: "local",
        droppable: false,
        navLinks: true,
        buttonText: {
          month: "月",
          week: "週",
          day: "日",
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
        ],
        customButtons: {
          prev: {
            click: this.handleClickPrev,
          },
          next: {
            click: this.handleClickNext,
          },
          today: {
            text: "今日",
            click: this.handleClickToday,
          },
          dayGridMonth: {
            text: "月",
            click: () => this.handleChangeView("dayGridMonth"),
          },
          timeGridWeek: {
            text: "週",
            click: () => this.handleChangeView("timeGridWeek"),
          },
          timeGridDay: {
            text: "日",
            click: () => this.handleChangeView("timeGridDay"),
          },
        },
        themeSystem: "bootstrap",
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        windowResize: () => {
          this.getInitialView();
        },
        disableDragging: true,
        initialView: this.getInitialView(),
        initialDate: moment(new Date()).toDate(),
        editable: false,
        selectable: true,
        selectMirror: true,
        eventStartEditable: false,
        dayMaxEvents: 3,
        weekends: true,
        eventClick: this.editEvent,
        moreLinkContent: function (arg) {
          return `+${arg.num} もっと見る`;
        },
      },
      currentEvents: [],
      showModal: false,
      eventModal: false,
      submitted: false,
      submit: false,
      newEventData: {},
      edit: {},
      calendarRange: null,
      deleteId: {},
      editevent: {
        id: "",
        day: "",
        startTime: "",
        endTime: "",
        userId: "",
        username: "",
        deletedAt: "",
      },
      params: {
        startTime: "",
        endTime: "",
        day: "",
        userId: "",
        facilityId: "",
      },
      calendarApi: null,
    };
  },
  components: {
    Layout,
    PageHeader,
    FullCalendar,
    flatPickr,
    "v-select": vSelect,
  },
  mounted() {
    this.getStaffSchedule();
  },
  methods: {
    handleClickPrev: debounce(function () {
      this.calendarApi.prev();
      this.calendarRange = this.calendarApi.currentData.dateProfile.renderRange;
      this.refreshCalendar();
    }, 500),
    handleClickNext: debounce(function () {
      this.calendarApi.next();
      this.calendarRange = this.calendarApi.currentData.dateProfile.renderRange;
      this.refreshCalendar();
    }, 500),
    handleClickToday: debounce(function () {
      this.calendarApi.today();
      this.refreshCalendar();
    }, 500),
    handleChangeView(type) {
      this.calendarApi.changeView(type);
      this.refreshCalendar();
    },
    convertHoursToMinutes(value) {
      const [hours, minutes] = value.split(":");
      const totalMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);
      return totalMinutes;
    },
    isValidTime(value) {
      if (value === null || value === "") return true;

      const [startHour, startMin] = value.split(":").map(Number);
      const [endHour, endMin] = this.endTime.split(":").map(Number);

      if (
        startHour > endHour ||
        (startHour === endHour && startMin >= endMin)
      ) {
        return false;
      }

      return true;
    },
    /**
     *
     * @param {Event} event
     */
    fileChange(event) {
      const {
        target: { files = null },
      } = event;
      this.dropZoneRef = files[0] || null;
      const checked = this.validateFile(this.dropZoneRef);
      if (checked) this.upload();
    },

    /**
     *
     * @param {File} file
     * @return boolean
     */
    validateFile(file) {
      const allowedExtensions = [
        "text/csv",
        "application/csv",
        "text/comma-separated-values",
        "text/anytext",
      ];

      if (!file) return false;

      if (!allowedExtensions.includes(file.type)) {
        this.textRef = i18n.global.t("msg.t-file-type-not-accepted");
        return false;
      }

      if (file.size / 1024 / 1024 > MAX_FILE_SIZE) {
        this.textRef = i18n.global.t("msg.t-exceed-filesize-limit", {
          size: 10,
        });
        return false;
      }

      return true;
    },

    /**
     *
     * @param {DragEvent} event
     */
    handleDropZone(event) {
      this.dropZoneRef = event.dataTransfer.files?.[0] || null;

      const checked = this.validateFile(this.dropZoneRef);
      if (checked) this.upload();
    },

    async upload() {
      try {
        let response = null;
        response = await staffService.uploadStaff(
          this.facilityId,
          this.dropZoneRef
        );

        if (response) {
          this.dropZoneRef = null;
          this.uploadRef.value = null;
          await Swal.fire({
            title: "",
            text: this.$t("msg.t-import-all-records-success"),
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
          this.state.openModal = false;
          this.refreshCalendar();
        }
      } catch (e) {
        console.log("e", e);
        // const uploadInput = document.getElementById('upload');
        // uploadInput.value = null;
        this.uploadRef.value = null;
        const { message = [], statusCode = 500 } = e.data;
        if (statusCode === 500) this.textRef = i18n.global.t("t-500");
        if (message.length > 0) this.textRef = message.join("\n");
      }
    },
    createStaff: debounce(async function () {
      if (this.currentUser === ROLES.FACILITY_ACCOUNT) {
        return;
      }

      this.v$.params.$touch();

      if (this.v$.params.$invalid) {
        return;
      }

      const newStartTime = this.convertHoursToMinutes(this.params.startTime);
      const newEndTime = this.convertHoursToMinutes(this.params.endTime);
      try {
        await staffService.createStaff({
          ...this.params,
          facilityId: this.facilityId,
          startTime: newStartTime,
          endTime: newEndTime,
        });
        await Swal.fire({
          title: "",
          text: this.$t("msg.saved"),
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        this.refreshCalendar();
        this.params = {
          startTime: "",
          endTime: "",
          day: "",
          userId: "",
        };
        this.v$.params.$reset();
      } catch (error) {
        await Swal.fire({
          title: "",
          text: error?.data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }, 500),
    getTitle(staff) {
      const start = convertMinutesToTimes(Number(staff.startTime));
      const end = convertMinutesToTimes(Number(staff.endTime));

      return `${staff.user?.name || ""}(${start} ~ ${end})`;
    },
    convertTime(day, time) {
      const strDate = `${moment(day).format(
        "YYYY-MM-DD"
      )} ${this.toHoursAndMinutes(Number(time))}`;
      return moment(strDate).toDate();
    },
    async getStaffSchedule() {
      this.calendarApi = this.$refs.calendar.getApi();
      if (!this.facilityId) return;
      const {
        dateProfile: { renderRange },
      } = this.calendarApi.currentData;
      this.calendarRange = renderRange;
      const response = await staffService.getStaff({
        startTime: this.calendarRange.start,
        endTime: this.calendarRange.end,
        facilityId: this.facilityId,
      });
      if (!response) {
        return;
      } else {
        this.calendarOptions.events = response.map((e) => ({
          id: e.id,
          title: this.getTitle(e),
          start: this.convertTime(e.day, e.startTime),
          end: this.convertTime(e.day, e.endTime),
          className: "bg-soft-success",
          extendedProps: { ...e },
        }));
      }
      return [];
    },
    showDateTime(refValue) {
      this.$refs[refValue].$el._flatpickr.open();
    },
    clearDateTime(refValue) {
      this.$refs[refValue].$el._flatpickr.clear();
    },

    refreshCalendar() {
      this.params.userId = "";
      this.getStaffSchedule();
    },
    getInitialView() {
      if (window.innerWidth >= 768 && window.innerWidth < 1200) {
        return "timeGridWeek";
      } else if (window.innerWidth <= 768) {
        return "listMonth";
      } else {
        return "dayGridMonth";
      }
    },

    /**
     * Edit event modal submit
     */
    // eslint-disable-next-line no-unused-vars
    editSubmit: debounce(async function () {
      this.v$.editevent.$touch();

      if (this.v$.editevent.$invalid) {
        return;
      }

      this.submit = true;
      const editDay = this.editevent.day;
      const editEndTime = this.editevent.endTime;
      const editStartTime = this.editevent.startTime;
      const staffId = this.editevent.id;
      const userId = this.editevent.userId;
      const username = this.editevent.username;
      try {
        let body = {
          facilityId: this.facilityId,
          startTime: "",
          endTime: "",
          userId,
          day: editDay,
        };

        const setStartTime = this.convertHoursToMinutes(editStartTime);
        const setEndTime = this.convertHoursToMinutes(editEndTime);

        const response = await staffService.updateStaff(staffId, {
          ...body,
          startTime: setStartTime,
          endTime: setEndTime,
        });

        if (response) {
          this.closeModal();
          this.refreshCalendar();
          this.state.data = this.state.data.filter((f) => !f.added);
        }

        this.successmsg();
        this.eventModal = false;
      } catch (error) {
        await Swal.fire({
          title: "",
          text:
            error?.data?.message === "User Not Found"
              ? this.$t("msg.t-facility-not-exists", { field: `${username}` })
              : error.data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }, 500),

    /**
     * Delete event
     */
    async deleteEvent() {
      const accept = await Swal.fire({
        title: this.$t("timesheet.delete-popup-msg"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("t-cancel"),
      });
      if (accept.value) {
        try {
          const id = this.editevent.id;

          const response = await staffService.deleteStaff(id);
          if (!response.error) {
            this.closeModal();
            this.refreshCalendar();
          }
        } catch (error) {
          await Swal.fire({
            title: "",
            text: error?.data?.message,
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    },
    /**
     * Modal open for add event
     */
    dateClicked(info) {
      this.newEventData = info;
      this.showModal = true;
    },

    padToTwoDigits(num) {
      return num.toString().padStart(2, "0");
    },

    toHoursAndMinutes(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      return `${this.padToTwoDigits(hours)}:${this.padToTwoDigits(minutes)}`;
    },

    /**
     * Modal open for edit event
     */
    editEvent(info) {
      if (this.disabled) {
        return;
      }
      this.eventModal = true;
      this.edit = info.event.extendedProps;
      if (!this.state.data.find((t) => t.value === this.edit.userId)) {
        this.state.data.unshift({
          value: this.edit.user?.id || this.edit.userId,
          label: `${this.edit.user?.name}(${this.edit.user?.userIdStr})`,
          added: true,
        });
      }
      this.editevent.id = this.edit?.id;
      this.editevent.day = this.edit?.day;
      this.editevent.endTime = this.toHoursAndMinutes(
        Number(this.edit?.endTime)
      );
      this.editevent.startTime = this.toHoursAndMinutes(
        Number(this.edit?.startTime)
      );
      this.editevent.userId = this.edit.user?.id || this.edit.userId;
      this.editevent.username = `${this.edit.user?.name}(${this.edit.user?.userIdStr})`;
      this.editevent.deletedAt = this.edit.user?.deletedAt;
    },

    closeModal() {
      this.state.data = this.state.data.filter((f) => !f.added);
      this.v$.$reset();
      this.editevent.userId = null;
      this.eventModal = false;
    },

    /**
     * Show list of events
     */
    handleEvents(events) {
      this.currentEvents = events;
    },

    /**
     * Show successfull Save Dialog
     */
    successmsg() {
      Swal.fire({
        icon: "success",
        text: this.$t("msg.saved"),
        showConfirmButton: false,
        timer: 1000,
      });
    },
  },
  computed: {
    ...mapGetters({
      facilityId: "settings/getFacilityId",
    }),
    timeConfigStart() {
      return {
        enableTime: false,
        noCalendar: true,
        dateFormat: "H:i",
        maxTime: this.params.endTime
          ? this.toHoursAndMinutes(
              this.convertHoursToMinutes(this.params.endTime) - 1
            )
          : null,
        allowInput: true,
      };
    },
    timeConfigEnd() {
      return {
        enableTime: false,
        noCalendar: true,
        allowInput: true,
        dateFormat: "H:i",
        minTime: this.params.startTime
          ? this.toHoursAndMinutes(
              this.convertHoursToMinutes(this.params.startTime) + 1
            )
          : null,
      };
    },
    timeEditConfigStart() {
      return {
        enableTime: false,
        noCalendar: true,
        allowInput: true,
        dateFormat: "H:i",
        maxTime: this.editevent.endTime
          ? this.toHoursAndMinutes(
              this.convertHoursToMinutes(this.editevent.endTime) - 1
            )
          : null,
      };
    },
    timeEditConfigEnd() {
      return {
        enableTime: false,
        noCalendar: true,
        allowInput: true,
        dateFormat: "H:i",
        minTime: this.editevent.startTime
          ? this.toHoursAndMinutes(
              this.convertHoursToMinutes(this.editevent.startTime) + 1
            )
          : null,
      };
    },
  },
  watch: {
    facilityId: function () {
      this.refreshCalendar();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div v-if="!disabled" class="col-xl-3">
            <div class="card card-h-100">
              <div class="card-body">
                <div class="flex flex-row justify-between header-title-style">
                  <span class="shift-style-text">{{
                    $t("timesheet.staff-form")
                  }}</span>
                  <button
                    :disabled="disabled"
                    href="#"
                    @click="() => openUploadModal()"
                    class="btn btn-primary shift-style"
                    id="btn-new-event"
                  >
                    <span class="button-styled">{{ $t("t-import") }}</span>
                  </button>
                </div>
                <div class="form-styled">
                  <form @submit.prevent="createStaff">
                    <div class="col-12">
                      <div class="mb-3">
                        <div class="flex flex-column">
                          <label class="form-styled-text form-label"
                            >{{ $t("timesheet.user")
                            }}<span class="text-danger">*</span></label
                          >
                          <v-select
                            :disabled="disabled"
                            class="style-chooser"
                            v-model="params.userId"
                            :reduce="(user) => user.value"
                            label="label"
                            :placeholder="this.$t('timesheet.user-placeholder')"
                            :options="state.data"
                          >
                            <template
                              #no-options="{ search, searching, loading }"
                              >{{ $t("t-no-data") }}</template
                            >
                          </v-select>
                          <div
                            v-if="v$.params.userId.$error"
                            class="invalid-feedback d-flex flex-column"
                          >
                            <span v-if="v$.params.userId.required.$invalid">{{
                              v$.params.userId.required.$message
                            }}</span>
                          </div>
                        </div>
                        <div class="flex flex-column pt-3">
                          <label class="form-styled-text"
                            >{{ $t("timesheet.date") }}
                            <span class="text-danger">*</span></label
                          >
                          <div
                            class="position-relative flex flex-row form-icon right"
                          >
                            <flat-pickr
                              :disabled="disabled"
                              ref="createDatePicker"
                              v-model="params.day"
                              :config="config"
                              :placeholder="
                                this.$t('timesheet.date-placeholder')
                              "
                              class="form-control thiem"
                            >
                            </flat-pickr>
                            <div class="d-flex align-items-center date-picker">
                              <i
                                class="bx bx-calendar-alt"
                                @click="showDateTime('createDatePicker')"
                              ></i>
                            </div>
                          </div>
                          <div
                            v-if="v$.params.day.$error"
                            class="invalid-feedback d-flex flex-column"
                          >
                            <span v-if="v$.params.day.required.$invalid">{{
                              v$.params.day.required.$message
                            }}</span>
                          </div>
                        </div>
                        <div class="flex flex-column pt-3 pb-2">
                          <label class="form-styled-text"
                            >{{ $t("timesheet.time-picker") }}
                            <span class="text-danger">*</span></label
                          >
                          <div class="row">
                            <div class="col-lg-5 m-0">
                              <flat-pickr
                                :disabled="disabled"
                                v-maska="['', '##:##']"
                                ref="timePicker"
                                v-model="params.startTime"
                                :config="timeConfigStart"
                                :placeholder="
                                  this.$t('timesheet.start-time-placeholder')
                                "
                                class="form-control"
                              ></flat-pickr>
                            </div>
                            <div class="col-lg-2 m-0 text-center pt-2">~</div>
                            <div class="col-lg-5 m-0">
                              <flat-pickr
                                :disabled="disabled"
                                v-maska="['', '##:##']"
                                ref="timePicker"
                                v-model="params.endTime"
                                :config="timeConfigEnd"
                                :placeholder="
                                  this.$t('timesheet.end-time-placeholder')
                                "
                                class="form-control"
                              ></flat-pickr>
                              <!-- <div v-if="v$.params.endTime.$error" class="invalid-feedback d-flex flex-column">
                                <span v-if="v$.params.endTime.required.$invalid">{{
                                  v$.params.endTime.required.$message
                                }}</span>
                              </div> -->
                            </div>
                            <div
                              v-if="v$.params.startTime.$error"
                              class="invalid-feedback d-flex flex-column"
                            >
                              <span
                                v-if="v$.params.startTime.required.$invalid"
                                >{{
                                  v$.params.startTime.required.$message
                                }}</span
                              >
                            </div>
                            <div
                              v-else-if="v$.params.endTime.$error"
                              class="invalid-feedback d-flex flex-column"
                            >
                              <span
                                v-if="v$.params.endTime.required.$invalid"
                                >{{ v$.params.endTime.required.$message }}</span
                              >
                              <span
                                v-if="
                                  !v$.params.endTime.required.$invalid &&
                                  v$.params.endTime.isGreater.$invalid
                                "
                                >{{
                                  v$.params.endTime.isGreater.$message
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex pt-2">
                      <button
                        :disabled="disabled"
                        type="submit"
                        class="btn btn-primary w-100"
                        id="btn-new-event"
                      >
                        <i class="mdi mdi-plus"></i>
                        {{ $t("timesheet.staff-form") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div :class="disabled ? 'col-xl-12' : 'col-xl-9'">
            <div class="card card-h-100">
              <div class="card-body">
                <FullCalendar
                  :options="calendarOptions"
                  ref="calendar"
                  class="custom-calendar"
                >
                </FullCalendar>
              </div>
            </div>
          </div>
          <b-modal
            v-model="state.openModal"
            hide-footer
            class="v-modal-custom"
            centered
          >
            <template #title>
              <div>
                <span class="font-weight-bold">CSV</span
                >{{ $t("t-csv-upload") }}
              </div>
            </template>
            <!-- <DropZone @drop.prevent="drop" @selectedFile="selectedFile" accept=".csv"
                        :allowedExtensions="['text/csv', 'application/csv']" /> -->
            <label
              for="upload"
              @dragover="(e) => e.preventDefault()"
              @drop.prevent="handleDropZone"
              draggable="true"
              id="dropZone"
              class="flex flex-column justify-content-center align-items-center modalSpacing dropZone"
            >
              <i class="mdi mdi-cloud-upload fs-30"></i>
              <span>Drop files here or click to upload.</span>
              <span v-if="dropZoneRef">{{ dropZoneRef.name }}</span>
              <input
                @change="fileChange"
                type="file"
                id="upload"
                ref="uploadRef"
                accept=".csv"
                multiple="false"
                class="hidden"
              />

              <!-- <button @click="downloadTemplate" class="download-button flex justify-content-center align-items-center bg-white">
              <i class="mdi mdi-download-circle fs-24"></i>
              <span>{{ $t('t-download-csv') }}</span>
            </button> -->
            </label>
            <div v-if="textRef" class="highlight">
              <p class="error-message">{{ textRef }}</p>
            </div>
          </b-modal>
        </div>
        <div style="clear: both"></div>
      </div>
    </div>

    <!-- Edit Modal -->
    <b-modal
      v-if="!disabled"
      v-model="eventModal"
      :title="this.$t('timesheet.edit-form')"
      title-class="font-18"
      @hide="closeModal"
      hide-footer
      body-class="p-3"
      ref="modal"
      id="edit_container"
    >
      <div class="edit-line-style" />
      <form @submit.prevent="editSubmit" class="px-5">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <div class="flex flex-column">
                <label class="form-styled-text"
                  >{{ $t("timesheet.user")
                  }}<span class="text-danger">*</span></label
                >
                <v-select
                  class="style-chooser"
                  v-model="editevent.userId"
                  :reduce="(user) => user.value"
                  label="label"
                  :placeholder="this.$t('timesheet.user-placeholder')"
                  :options="state.data"
                ></v-select>
                <div
                  v-if="v$.editevent.userId.$error"
                  class="invalid-feedback d-flex flex-column"
                >
                  <span v-if="v$.editevent.userId.required.$invalid">{{
                    v$.editevent.userId.required.$message
                  }}</span>
                </div>
              </div>
              <div class="flex flex-column pt-3">
                <label class="form-styled-text"
                  >{{ $t("timesheet.date") }}
                  <span class="text-danger">*</span></label
                >
                <div
                  class="position-relative dateInput flex flex-row form-icon right"
                >
                  <flat-pickr
                    ref="editDatePicker"
                    v-model="editevent.day"
                    :config="config"
                    :placeholder="this.$t('timesheet.date-placeholder')"
                    class="form-control"
                  ></flat-pickr>
                  <div class="d-flex align-items-center date-picker">
                    <i
                      class="bx bx-calendar-alt"
                      @click="showDateTime('editDatePicker')"
                    ></i>
                  </div>
                </div>
                <div
                  v-if="v$.editevent.day.$error"
                  class="invalid-feedback d-flex flex-column"
                >
                  <span v-if="v$.editevent.day.required.$invalid">{{
                    v$.editevent.day.required.$message
                  }}</span>
                </div>
              </div>
              <div class="flex flex-column pt-3 pb-2">
                <label class="form-styled-text"
                  >{{ $t("timesheet.time-picker") }}
                  <span class="text-danger">*</span></label
                >
                <div class="flex justify-between flex-row">
                  <flat-pickr
                    v-maska="['', '##:##']"
                    ref="timeEditStartPicker"
                    style="width: 145px; flex-shrink: 0"
                    v-model="editevent.startTime"
                    :config="timeEditConfigStart"
                    :placeholder="this.$t('timesheet.start-time-placeholder')"
                    class="form-control"
                  ></flat-pickr>
                  <span class="text-center pt-2">~</span>
                  <flat-pickr
                    v-maska="['', '##:##']"
                    ref="timeEditEndPicker"
                    style="width: 145px; flex-shrink: 0"
                    v-model="editevent.endTime"
                    :config="timeEditConfigEnd"
                    :placeholder="this.$t('timesheet.end-time-placeholder')"
                    class="form-control"
                  ></flat-pickr>
                </div>
                <div
                  v-if="v$.editevent.startTime.$error"
                  class="invalid-feedback d-flex flex-column"
                >
                  <span v-if="v$.editevent.startTime.required.$invalid">{{
                    v$.editevent.startTime.required.$message
                  }}</span>
                </div>
                <div
                  v-else-if="v$.editevent.endTime.$error"
                  class="invalid-feedback d-flex flex-column"
                >
                  <span v-if="v$.editevent.endTime.required.$invalid">{{
                    v$.editevent.endTime.required.$message
                  }}</span>
                  <span
                    v-if="
                      !v$.editevent.endTime.required.$invalid &&
                      v$.editevent.endTime.isGreater.$invalid
                    "
                    >{{ v$.editevent.endTime.isGreater.$message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex gap-3 justify-content-end">
          <b-button variant="light" @click="closeModal">{{
            $t("t-cancel")
          }}</b-button>
          <b-button
            class="b-button-width"
            variant="danger"
            @click="() => deleteEvent()"
            >{{ $t("t-delete") }}</b-button
          >
          <b-button class="b-button-width" variant="success" type="submit">{{
            $t("t-save")
          }}</b-button>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>

<style scoped lang="scss">
:deep {
  .fc-event {
    cursor: pointer !important;
  }

  .custom-calendar {
    & .fc-next-button,
    .fc-prev-button {
      background-color: rgba(144, 196, 239, 0.15) !important;
      padding: 0.3rem 0.4rem;
      border-radius: 0 0.25rem 0.25rem 0;
      color: #6691e3 !important;

      &:hover {
        color: #2f71f5 !important;
      }
    }

    & .fc-today-button {
      background-color: rgba(144, 196, 239, 0.15) !important;
      color: #6691e3 !important;
    }

    & .fc-dayGridMonth-button,
    .fc-timeGridWeek-button,
    .fc-timeGridDay-button {
      background-color: rgba(144, 196, 239, 0.15) !important;
      color: #6691e3 !important;

      &:hover {
        color: #2f71f5 !important;
      }
    }

    & .fc-dayGridMonth-button.active,
    .fc-timeGridWeek-button.active,
    .fc-timeGridDay-button.active {
      background: var(--vz-primary) !important;
      color: white !important;
    }
  }

  .fc-timegrid-event {
    height: auto !important;
  }

  .style-chooser {
    background-color: var(--vz-input-bg) !important;

    .vs__search::placeholder {
      color: #878a99;
    }

    .vs__dropdown-toggle {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .vs--disabled .vs__dropdown-toggle,
  .vs--disabled .vs__clear,
  .vs--disabled .vs__search,
  .vs--disabled .vs__selected,
  .vs--disabled .vs__open-indicator {
    background-color: var(--vz-input-disabled-bg) !important;
  }

  .style-chooser .vs__search,
  .style-chooser .vs__selected {
    font-size: 0.85rem;
  }

  .style-chooser .vs__dropdown-toggle,
  .style-chooser .vs__dropdown-menu {
    color: #394066;
  }

  .style-chooser .vs__clear,
  .style-chooser .vs__open-indicator {
    fill: #394066;
  }

  .swal2-html-container {
    text-align: left !important;
  }
}

.shift-style {
  display: flex;
  width: 88px;
  height: 25px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  background-color: #eef3fc;
}

.button-styled {
  color: #5c86e0;
  text-align: center;
  font-family: Lato;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

[data-layout-mode="dark"] {
  .shift-style {
    background-color: var(--vz-multi-select-bg) !important;
    color: var(--vz-multi-select-color) !important;
    border: none;
  }

  .button-styled {
    color: var(--vz-multi-select-color) !important;
  }
}

.shift-style-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.form-styled {
  padding: 12px 0 25px 0;
}

.form-styled-text {
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.uploadLabel {
  padding: 8px 16px;
  border: 1px solid #ccc;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
}

.hidden {
  display: none;
}

.b-button-width {
  width: 80px;
  height: 38px;
}

.modalSpacing {
  row-gap: 14px;
}

.download-button {
  padding: 0px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  column-gap: 12px;
}

.highlight {
  font-size: 0.875em;
  color: #ed5e5e;
  white-space: pre-line;
}

.error-message {
  word-wrap: break-word;
  max-height: 114px;
  overflow: auto;
  word-break: break-all;
  white-space: pre-line;
}

.dropZone {
  min-height: 150px;
  border: 1px dashed #ccc;
  border-radius: 4px;
}

.dropZone > i {
  font-size: 30px;
}

v-select,
v-select::placeholder {
  font: 1.25rem/3 sans-serif;
}

.header-title-style {
  border-bottom: 1px solid var(--vz-border-color) !important;
  padding-bottom: 20px;
  padding-top: 10px;
}

.edit-line-style {
  border-bottom: 1px solid var(--vz-border-color) !important;
  margin-bottom: 20px;
}

.date-form {
  background-color: #fff !important;
}
</style>
